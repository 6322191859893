import { useNavigate } from "react-router-dom";
import moment from "moment";

const SignatureDisclosure = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full overflow-y-auto overflow-x-hidden flex flex-col justify-start items-center">
      <div className="min-h-14 h-14 w-full bg-black flex flex-row justify-center items-center">
        <div className="w-full max-w-[1200px] flex flex-row justify-between items-center text-white h-14">
          <p className="font-display text-2xl cursor-pointer">
            Hi-Tech Power Systems
          </p>
          <div className="flex flex-row justify-end gap-4 items-center">
            <p className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer">
              Contact Us
            </p>
            <p
              className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Client Portal
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start my-8 w-full max-w-[1200px] mx-auto items-start gap-2">
        <h1 className="font-black text-xl font-body mb-4">
          ELECTRONIC RECORD AND SIGNATURE DISCLOSURE
        </h1>
        <p className="w-full">
          From time to time, Hi-Tech Power Systems (we, us or Company) may be
          required by law to provide to you certain written notices or
          disclosures. Described below are the terms and conditions for
          providing to you such notices and disclosures electronically through
          the HyperClient Portal. Please read the information below carefully
          and thoroughly, and if you can access this information electronically
          to your satisfaction and agree to this Electronic Record and Signature
          Disclosure (ERSD), please confirm your agreement by selecting the
          check-box next to ‘I agree to use electronic records and signatures’
          before clicking ‘CONTINUE’ within the HyperClient portal.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Getting paper copies
        </h3>
        <p className="w-full">
          At any time, you may request from us a paper copy of any record
          provided or made available electronically to you by us. You will have
          the ability to download and print documents we send to you through the
          HyperClient portal during and immediately after the signing session
          and, if you elect to create a HyperClient portal account, you may
          access the documents for a limited period of time (usually 30 days)
          after such documents are first sent to you. After such time, if you
          wish for us to send you paper copies of any such documents from our
          office to you, you will be charged a{" "}
          <span className="font-medium">$0.75</span> per-page fee. You may
          request delivery of such paper copies from us by following the
          procedure described below.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Withdrawing your consent
        </h3>
        <p className="w-full">
          If you decide to receive notices and disclosures from us
          electronically, you may at any time change your mind and tell us that
          thereafter you want to receive required notices and disclosures only
          in paper format. How you must inform us of your decision to receive
          future notices and disclosure in paper format and withdraw your
          consent to receive notices and disclosures electronically is described
          below.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Consequences of changing your mind
        </h3>
        <p className="w-full">
          If you elect to receive required notices and disclosures only in paper
          format, it will slow the speed at which we can complete certain steps
          in transactions with you and delivering services to you because we
          will need first to send the required notices or disclosures to you in
          paper format, and then wait until we receive back from you your
          acknowledgment of your receipt of such paper notices or disclosures.
          Further, you will no longer be able to use the HyperClient portal to
          receive required notices and consents electronically from us or to
          sign electronically documents from us.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          All notices and disclosures will be sent to you electronically
        </h3>
        <p className="w-full">
          Unless you tell us otherwise in accordance with the procedures
          described herein, we will provide electronically to you through the
          HyperClient portal all required notices, disclosures, authorizations,
          acknowledgements, and other documents that are required to be provided
          or made available to you during the course of our relationship with
          you. To reduce the chance of you inadvertently not receiving any
          notice or disclosure, we prefer to provide all of the required notices
          and disclosures to you by the same method and to the same address that
          you have given us. Thus, you can receive all the disclosures and
          notices electronically or in paper format through the paper mail
          delivery system. If you do not agree with this process, please let us
          know as described below. Please also see the paragraph immediately
          above that describes the consequences of your electing not to receive
          delivery of the notices and disclosures electronically from us.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          How to contact Hi-Tech Power Systems:
        </h3>
        <p className="w-full">
          You may contact us to let us know of your changes as to how we may
          contact you electronically, to request paper copies of certain
          information from us, and to withdraw your prior consent to receive
          notices and disclosures electronically as follows:
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          To advise Hi-Tech Power Systems of your new email address
        </h3>
        <p className="w-full">
          To let us know of a change in your email address where we should send
          notices and disclosures electronically to you, you must send an email
          message to us at{" "}
          <a
            href="mailto:dev@hitechpowersystems.com"
            className="underline underline-offset-2 text-blue-500"
          >
            dev@hitechpowersystems.com
          </a>{" "}
          and in the body of such request you must state: your previous email
          address, your new email address.If you created a HyperClient Portal
          account, you may update it with your new email address through your
          account preferences.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          To request paper copies from Hi-Tech Power Systems
        </h3>
        <p className="w-full">
          To request delivery from us of paper copies of the notices and
          disclosures previously provided by us to you electronically, you must
          send us an email to{" "}
          <a
            href="mailto:dev@hitechpowersystems.com"
            className="underline underline-offset-2 text-blue-500"
          >
            dev@hitechpowersystems.com
          </a>{" "}
          and in the body of such request you must state your email address,
          full name, mailing address, and telephone number.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          To withdraw your consent with Hi-Tech Power Systems
        </h3>
        <p className="w-full">
          To inform us that you no longer wish to receive future notices and
          disclosures in electronic format you may:
          <ul className="list-decimal w-11/12 mx-auto my-2">
            <li className="w-full">
              decline to sign a document from within your signing session, and
              on the subsequent page, select the check-box indicating you wish
              to withdraw your consent, or you may;
            </li>
            <li className="w-full">
              send us an email to{" "}
              <a
                href="mailto:dev@hitechpowersystems.com"
                className="underline underline-offset-2 text-blue-500"
              >
                dev@hitechpowersystems.com
              </a>{" "}
              and in the body of such request you must state your email, full
              name, mailing address, and telephone number.
            </li>
          </ul>
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Required hardware and software
        </h3>
        <p className="w-full">
          The minimum system requirements for using the HyperClient portal may
          change over time.
        </p>
        <p className="w-full">
          The current system requirements are found here:{" "}
          <a
            href="/hardwareRequirements"
            className="underline underline-offset-2 text-blue-500"
          >
            Signer Guide Signing System Requirements
          </a>
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Acknowledging your access and consent to receive and sign documents
          electronically
        </h3>
        <p className="w-full">
          To confirm to us that you can access this information electronically,
          which will be similar to other electronic notices and disclosures that
          we will provide to you, please confirm that you have read this ERSD,
          and (i) that you are able to print on paper or electronically save
          this ERSD for your future reference and access; or (ii) that you are
          able to email this ERSD to an email address where you will be able to
          print on paper or save it for your future reference and access.
          Further, if you consent to receiving notices and disclosures
          exclusively in electronic format as described herein, then select the
          check-box next to ‘I agree to use electronic records and signatures’
          before clicking ‘CONTINUE’ within the HyperClient portal.
        </p>
        <p className="w-full">
          By selecting the check-box next to ‘I agree to use electronic records
          and signatures’, you confirm that:
          <ul className="list-disc w-11/12 mx-auto my-2">
            <li className="w-full">
              You can access and read this Electronic Record and Signature
              Disclosure; and You can print on paper this Electronic Record and
              Signature Disclosure, or save or send this Electronic Record and
              Disclosure to a location where you can print it, for future
              reference and access; and
            </li>
            <li className="w-full">
              Until or unless you notify Hi-Tech Power Systems as described
              above, you consent to receive exclusively through electronic means
              all notices, disclosures, authorizations, acknowledgements, and
              other documents that are required to be provided or made available
              to you by Hi-Tech Power Systems during the course of your
              relationship with Hi-Tech Power Systems.
            </li>
          </ul>
        </p>
      </div>
      <div className="w-full flex flex-row justify-center items-center border-t border-gray-300">
        <div className="h-14 w-full max-w-[1200px] mx-auto flex flex-row justify-between items-center text-xs py-3">
          <p>
            All rights reserved © Copyright 1986 - {moment().format("YYYY")} |{" "}
            <a
              href="https://hitechpowersystems.com"
              target="_blank"
              rel="noreferrer"
              className="font-medium"
            >
              Hi-Tech Power Systems
            </a>
          </p>
          <p>
            Powered by{" "}
            <a
              href="https://liveuptothehype.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-150 ease-in-out hover:text-[#f20c2e] font-medium"
            >
              Hypertek Solutions LLC
            </a>{" "}
            | Live up to the hype!
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignatureDisclosure;
