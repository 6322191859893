import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../views/dashboard";
import Invoices from "../views/invoices";
import PaymentHistory from "../views/paymentHistory";

const CoreLayout = ({ authState, authDispatch, history }) => {
  return authState.isAuth ? (
    <Routes>
      <Route index element={<Dashboard authState={authState} authDispatch={authDispatch} />} />
      <Route path="invoices" element={<Invoices authState={authState} authDispatch={authDispatch} />} />
      <Route path="paymentHistory" element={<PaymentHistory authState={authState} authDispatch={authDispatch} />} />
    </Routes>
  ) : (
    <Navigate to="/login" />
  );
};

export default CoreLayout;
