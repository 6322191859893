import axiosInstance from "./axios";

export const GetUser = () => {
  return axiosInstance.get("/portal/user");
};

export const SignIn = (data) => {
  return axiosInstance.post("/portal/login", data);
};

export const Register = (data) => {
  return axiosInstance.post("/portal/register", data);
};

export const GetDashboard = () => {
  return axiosInstance.get("/portal/dashboard");
};

export const AcceptQuote = (data) => {
  return axiosInstance.post("/portal/acceptQuote", data);
};

export const RejectQuote = (data) => {
  return axiosInstance.post("/portal/rejectQuote", data);
};

export const SubmitContact = (data) => {
  return axiosInstance.post("/portal/contact", data);
};

export const GetInvoice = (data) => {
  return axiosInstance.post("/portal/getInvoice", data);
};

export const CreateIntent = (data) => {
  return axiosInstance.post("/payment/createIntent", data);
};

export const CheckPayment = (data) => {
  return axiosInstance.post("/payment/checkPayment", data);
};

export const GetInvoices = () => {
  return axiosInstance.get("/portal/invoices");
};

export const GetPaymentHistory = () => {
  return axiosInstance.get("/portal/payments");
};
