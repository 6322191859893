import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SignIn } from "../actions/auth";
import validator from "validator";
import toast from "react-hot-toast";

const Login = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (authState.isAuth) {
      navigate("/");
    }
  }, []);

  const submitLogin = () => {
    setLoading(true);
    let valid = true;
    if (!validator.isEmail(email)) {
      valid = false;
      toast.error("Please enter a valid email");
    }
    if (valid) {
      SignIn({ email, password })
        .then((res) => {
          let payload = {
            token: res.data.token,
            email: email,
            user: res.data.user,
          };
          toast.success("Logged in successfully!");
          localStorage.setItem("auth-token", res.data.token);
          authDispatch({
            type: "valid-loggin",
            payload: payload,
          });
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <div className="w-full h-full flex flex-row justify-center items-center bg-[#1e1e1e]">
      <div className="h-full w-10/12 flex flex-row justify-center items-center gap-16">
        <div className="h-3/5 w-2/3 flex flex-col justify-center items-start text-white gap-2">
          <h2 className="text-5xl font-medium tracking-tight">
            Hi-Tech Power Systems
          </h2>
          <p className="font-light text-xl">
            Expert generator, truck and trailer repairs, on-site and in-shop.
          </p>
          <div className="w-full flex-grow flex flex-col justify-center items-start gap-3">
            <p className="font-light">
              With two convenient locations or on-site services, our technicians
              are equipped
              <br />
              to handle any repair needs for your generators, trucks, and
              trailers.
            </p>
          </div>
          <div className="w-full h-fit flex flex-row justify-start items-center gap-10">
            <div className="flex flex-col justify-start items-start gap-1">
              <p className="text-lg font-sans">Augusta Location</p>
              <p className="text-md font-light">2479 Doug Barnard Pkwy</p>
              <p className="text-md font-light">Augusta, GA 30906</p>
              <p className="text-md font-light">
                Phone:{" "}
                <a
                  className="underline underline-offset-4 hover:text-blue-600 transition-all ease-in-out duration-150"
                  href="tel:7067908111"
                >
                  706-790-8111
                </a>
              </p>
              <p className="text-md font-light">
                Fax:{" "}
                <a
                  className="underline underline-offset-4 hover:text-blue-600 transition-all ease-in-out duration-150"
                  href="fax:7067904368"
                >
                  706-790-4368
                </a>
              </p>
            </div>
            <div className="flex flex-col justify-start items-start gap-1 pl-12 border-l border-white">
              <p className="text-lg font-sans">North Augusta Location</p>
              <p className="text-md font-light">1182 Edgefield Rd</p>
              <p className="text-md font-light">North Augusta, SC 29841</p>
              <p className="text-md font-light">
                Phone:{" "}
                <a
                  className="underline underline-offset-4 hover:text-blue-600 transition-all ease-in-out duration-150"
                  href="tel:8036130101"
                >
                  803-613-0101
                </a>
              </p>
              <p className="text-md font-light">
                Fax:{" "}
                <a
                  className="underline underline-offset-4 hover:text-blue-600 transition-all ease-in-out duration-150"
                  href="fax:8036130323"
                >
                  803-613-0323
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="h-3/4 w-[400px] flex flex-col justify-center items-center">
          <div className="bg-white h-[650px] w-[400px] rounded-2xl px-10 py-12 shadow-2xl flex flex-col justify-between items-center">
            <div className="flex flex-col justify-start items-center gap-2">
              <h3 className="text-4xl text-center font-display">
                Client Portal
              </h3>
              <p className="uppercase text-sm font-medium font-sans text-center">
                Access your quotes and invoices
              </p>
            </div>
            {!loading ? (
              <div className="w-full h-fit">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-black focus:ring-transparent"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="text-gray-500 text-xs text-right mt-1 hover:text-black transition-all ease-in-out duration-150 cursor-pointer">
                    Login With Phone Number
                  </p>
                </div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="password"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-black focus:ring-transparent"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <p className="text-gray-500 text-xs text-right mt-1 hover:text-black transition-all ease-in-out duration-150 cursor-pointer">
                    Forgot Password
                  </p>
                </div>
                <button
                  className="mt-8 w-full bg-black text-white border border-black py-2 rounded-md transition-all ease-in-out duration-150 hover:text-black hover:bg-white cursor-pointer"
                  onClick={() => submitLogin()}
                >
                  Login
                </button>
              </div>
            ) : (
              <div className="w-full flex-1 justify-center items-center flex flex-col gap-4">
                <svg
                  className="animate-spin h-8 w-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-black"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="#c9c9c9"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75 fill-black"
                    fill="#c9c9c9"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <p className="text-sm font-medium text-center opacity-85 animate-pulse">
                  Loading...
                </p>
              </div>
            )}
            <div className="flex flex-row justify-center items-center">
              <p className="text-xs font-sans font-light uppercase text-gray-500 transition-all ease-in-out duration-150 hover:text-black cursor-pointer">
                Contact Support
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
