import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider, AuthConsumer } from "./context";

const root = ReactDOM.createRoot(
  document.getElementById("hi-tech-client-portal")
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AuthConsumer>
        {(authState) => (
          <BrowserRouter>
            <App authState={authState} authDispatch={authState.authDispatch} />
          </BrowserRouter>
        )}
      </AuthConsumer>
    </AuthProvider>
  </React.StrictMode>
);
