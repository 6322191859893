import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetInvoices, GetPaymentHistory } from "../actions/auth";
import { GenerateInvoicePDF } from "../components/pdf";
import moment from "moment";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

const PaymentHistory = ({ authState, authDispatch }) => {
  const [payments, setPayments] = useState([]);
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("auth-token");
    authDispatch({ type: "logged-out" });
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    let inView = true;
    if (inView) {
      reloadData();
    }
    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    setLoading(true);
    GetPaymentHistory()
      .then((res) => {
        setPayments(res.data.payments);
        setCompanyData(res.data.company);
        setTimeout(() => {
          setLoading(false);
        }, 700);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const openLocation = (location) => {
    if (location === "Augusta") {
      window.open("https://goo.gl/maps/Hv54mK3uT9tdZHfi6", "_blank");
    } else if (location === "North Augusta") {
      window.open("https://goo.gl/maps/YsYqNdpG7YK5kXyj9", "_blank");
    }
  };

  const currencyFormat = (value) => {
    let formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
    return formatted;
  };

  const renderPayment = (payment) => {
    return (
      <div
        className="w-full border border-gray-100 shadow-md flex flex-col justify-start items-center hover:scale-[1.01] hover:shadow-xl transition-all ease-in-out duration-150"
        key={payment.saleId}
      >
        <div className="flex flex-row justify-between w-full px-8 py-2 text-sm bg-gray-50">
          <p>Payment for {payment.reference}</p>
          <p>Payment Date: {dayjs(payment.date).format("MMMM Do YYYY")}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full gap-4 px-8 py-6">
          <div className="flex-col items-center justify-center gap-2 text-left">
            <div className="flex flex-row items-center justify-start gap-2">
              <p className="text-sm">Status:</p>
              <p className="text-sm font-medium">{payment.status.toUpperCase()}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-2">
              <p className="text-sm">Payment Method:</p>
              <p className="text-sm font-medium">
                {payment.cardBrand.toUpperCase()} ending in {payment.last4}
              </p>
            </div>
          </div>
          <div className="h-full w-[1px] bg-black" />
          <div className="flex-col justify-center gap-2 -ml-20 text-right items-right">
            <p className="text-xl font-bold">Total:</p>
            <p className="text-lg font-semibold">{currencyFormat(payment.total)}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row items-center justify-center w-full bg-black h-14">
        <div className="w-full max-w-[1440px] flex flex-row justify-between items-center text-white h-14">
          <p className="text-2xl cursor-pointer font-display">Hi-Tech Power Systems</p>
          <div className="flex flex-row items-center justify-end gap-4">
            <p
              className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer"
              onClick={() => navigate("/contactUs")}
            >
              Contact Us
            </p>
            <p className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#eb2f06] cursor-pointer" onClick={() => logout()}>
              Log Out
            </p>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[1440px] flex flex-col justify-start items-center mt-6 flex-grow">
        <h1 className="text-3xl font-bold">Welcome Back!</h1>
        <p className="text-xs font-medium text-gray-400 uppercase">Let's review what's new</p>
        <div className="flex flex-row items-center justify-between flex-grow w-full gap-5 mt-6">
          <div className="flex flex-col justify-start border-r border-r-black h-full w-[350px] py-8 px-12 gap-3">
            <button
              className="py-2 pl-6 text-xl font-black text-left uppercase duration-150 font-body hover:bg-slate-100 hover:text-slate-800"
              onClick={() => navigate("/")}
            >
              My Quotes
            </button>
            <button
              className="py-2 pl-6 text-xl font-black text-left uppercase duration-150 font-body hover:bg-slate-100 hover:text-slate-800"
              onClick={() => navigate("/invoices")}
            >
              My Invoices
            </button>
            <button
              className="font-body font-black uppercase text-xl border-l-4 border-[#ffc312] pl-6 text-left hover:bg-slate-100 hover:text-slate-800 duration-150 py-2"
              onClick={() => navigate("/paymentHistory")}
            >
              Payment History
            </button>
            <button
              className="py-2 pl-6 text-xl font-black text-left uppercase duration-150 font-body hover:bg-slate-100 hover:text-slate-800"
              onClick={() => navigate("/contactUs")}
            >
              Contact Us
            </button>
          </div>
          <div className="flex flex-col items-start justify-start flex-grow h-full gap-4 px-12 py-10">
            {!loading && payments.length > 0 ? (
              payments.map((payment) => renderPayment(payment))
            ) : loading ? (
              <div className="flex flex-col items-center justify-center flex-1 w-full gap-4">
                <svg className="w-8 h-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25 stroke-black" cx="12" cy="12" r="10" stroke="#c9c9c9" strokeWidth="4"></circle>
                  <path
                    className="opacity-75 fill-black"
                    fill="#c9c9c9"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <p className="text-sm font-medium text-center opacity-85 animate-pulse">Loading...</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center flex-1 w-full gap-4">
                <p className="text-sm font-medium text-center opacity-85">No Previous Payments Found</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full text-xs h-14">
          <p>
            All rights reserved © Copyright 1986 - {moment().format("YYYY")} |{" "}
            <a href="https://hitechpowersystems.com" target="_blank" rel="noreferrer" className="font-medium">
              Hi-Tech Power Systems
            </a>
          </p>
          <p>
            Powered by{" "}
            <a
              href="https://liveuptothehype.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-150 ease-in-out hover:text-[#f20c2e] font-medium"
            >
              Hypertek Solutions LLC
            </a>{" "}
            | Live up to the hype!
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
