import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Fax } from "@styled-icons/fluentui-system-regular/Fax";
import { Phone } from "@styled-icons/feather/Phone";
import { SubmitContact } from "../actions/auth";
import moment from "moment";
import validator from "validator";
import toast from "react-hot-toast";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const navigate = useNavigate();

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const updateValue = (e) => {
    let { name, value } = e.target;
    let tmp = data;
    data[name] = value;
    setData(tmp);
  };

  const submitContact = () => {
    setLoading(true);
    let failed = false;
    if (data.name.length < 3) {
      toast.error("Please enter a valid name.");
      failed = true;
    }
    if (!validator.isEmail(data.email) && !failed) {
      toast.error("Please enter a valid email.");
      failed = true;
    }
    if (!validator.isMobilePhone(data.phoneNumber) && !failed) {
      toast.error("Please enter a valid phone number.");
      failed = true;
    }
    if (data.message.length < 10 && !failed) {
      toast.error(
        "Please enter a valid message, containing minimum 10 characters."
      );
      failed = true;
    }
    if (!failed) {
      SubmitContact(data)
        .then((res) => {
          toast.success(
            "Message sent successfully! We will get back to you shortly!"
          );
          nameRef.current.value = "";
          emailRef.current.value = "";
          phoneRef.current.value = "";
          messageRef.current.value = "";
          setData({
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Something went wrong, please try again later.");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full overflow-y-auto overflow-x-hidden flex flex-col justify-start items-center">
      <div className="min-h-14 h-14 w-full bg-black flex flex-row justify-center items-center">
        <div className="w-full max-w-[1200px] flex flex-row justify-between items-center text-white h-14">
          <p className="font-display text-2xl cursor-pointer">
            Hi-Tech Power Systems
          </p>
          <div className="flex flex-row justify-end gap-4 items-center">
            <p className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer">
              Contact Us
            </p>
            <p
              className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Client Portal
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center my-8 w-full max-w-[1200px] mx-auto items-center gap-2 flex-1">
        <div className="w-full flex flex-1 flex-col justify-center items-center font-body gap-4">
          <h1 className="font-black text-3xl font-body uppercase tracking-wider">
            Contact Us
          </h1>
          <h3 className="font-body font-light text-center text-lg">
            Feel free to use the contact form to reach out ot us,
            <br />
            or use call / visit one of our locations listed on the left.
          </h3>
          <div className="w-full flex flex-row justify-between items-center font-body mt-4">
            <div className="h-full w-1/2 flex flex-col justify-center items-start">
              <h3 className="text-lg font-black uppercase my-2">
                Our Locations
              </h3>
              <div className="flex flex-row justify-between items-center w-10/12 my-2">
                <div className="flex flex-col justify-start items-start w-1/2">
                  <a
                    href="https://goo.gl/maps/SMT4E4j9qZRscP1aA"
                    target="_blank"
                    rel="noreferrer"
                    s
                  >
                    <p className="font-medium">Augusta Location</p>
                    <p className="font-light">2479 Doug Barnard Pkwy</p>
                    <p className="font-light">Augusta, GA 30906</p>
                  </a>
                  <a href="tel:7067908111">
                    <div className="flex flex-row justify-start items-center gap-1">
                      <Phone size={18} />
                      <p className="font-light">(706) 790 - 8111</p>
                    </div>
                  </a>
                  <a href="fax:7067904368">
                    <div className="flex flex-row justify-start items-center gap-1">
                      <Fax size={20} />
                      <p className="font-light">(706) 790 - 4368</p>
                    </div>
                  </a>
                </div>
                <div className="flex flex-col justify-start items-start w-1/2">
                  <a
                    href="https://goo.gl/maps/akhrKaCKN7VTp9tG7"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="font-medium">North Augusta Location</p>
                    <p className="font-light">1182 Edgefield Rd</p>
                    <p className="font-light">North Augusta, SC 29841</p>
                  </a>
                  <a href="tel:8036130101">
                    <div className="flex flex-row justify-start items-center gap-1">
                      <Phone size={18} />
                      <p className="font-light">(803) 613 - 0101</p>
                    </div>
                  </a>
                  <a href="fax:8036130323">
                    <div className="flex flex-row justify-start items-center gap-1">
                      <Phone size={18} />
                      <p className="font-light">(803) 613 - 0323</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="border-t border-gray-300 my-3 w-10/12 mr-auto" />
              <h3 className="text-lg font-black uppercase my-2">Email</h3>
              <a
                href="mailto:contact@hitechpowersystems.com"
                className="font-light my-2"
              >
                contact@hitechpowersystems.com
              </a>
              <div className="border-t border-gray-300 my-3 w-10/12 mr-auto" />
              <h3 className="text-lg font-black uppercase my-2">Hours</h3>
              <p className="font-light my-2">Monday - Friday, 8AM - 5PM</p>
            </div>
            <div className="h-full w-1/2 flex flex-col justify-center items-center gap-4">
              <div className="font-body flex flex-col w-10/12 pt-14">
                <label
                  htmlFor="name"
                  className="block font-light uppercase text-gray-700"
                >
                  Your Name
                </label>
                <div className="mt-1 w-full">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounder-sm border-gray-300 focus:border-black focus:ring-transparent sm:text-sm"
                    onChange={(e) => updateValue(e)}
                    ref={nameRef}
                  />
                </div>
              </div>
              <div className="font-body flex flex-col w-10/12">
                <label
                  htmlFor="email"
                  className="block font-light uppercase text-gray-700"
                >
                  Your Email Address
                </label>
                <div className="mt-1 w-full">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounder-sm border-gray-300 focus:border-black focus:ring-transparent sm:text-sm"
                    onChange={(e) => updateValue(e)}
                    ref={emailRef}
                  />
                </div>
              </div>
              <div className="font-body flex flex-col w-10/12">
                <label
                  htmlFor="phoneNumber"
                  className="block font-light uppercase text-gray-700"
                >
                  Phone Number
                </label>
                <div className="mt-1">
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="block w-full rounder-sm border-gray-300 focus:border-black focus:ring-transparent sm:text-sm"
                    onChange={(e) => updateValue(e)}
                    ref={phoneRef}
                  />
                </div>
              </div>
              <div className="font-body flex flex-col w-10/12">
                <label
                  htmlFor="message"
                  className="block font-light uppercase text-gray-700"
                >
                  Message
                </label>
                <div className="mt-1">
                  <textarea
                    rows={4}
                    name="message"
                    id="message"
                    className="block w-full rounded-sm border-gray-300 focus:border-black focus:ring-transparent sm:text-sm resize-none"
                    onChange={(e) => updateValue(e)}
                    ref={messageRef}
                  />
                </div>
              </div>
              <div className="font-body flex flex-row justify-end items-center w-10/12 mt-2">
                <button
                  className="bg-black border border-black font-body font-light text-white px-8 py-2 uppercase transition-all duration-150 ease-in-out hover:text-black hover:bg-white disabled:cursor-not-allowed disabled:hover:text-white disabled:hover:bg-black"
                  onClick={() => submitContact()}
                  disabled={loading}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-center items-center border-t border-gray-300">
        <div className="h-14 w-full max-w-[1200px] mx-auto flex flex-row justify-between items-center text-xs py-3">
          <p>
            All rights reserved © Copyright 1986 - {moment().format("YYYY")} |{" "}
            <a
              href="https://hitechpowersystems.com"
              target="_blank"
              rel="noreferrer"
              className="font-medium"
            >
              Hi-Tech Power Systems
            </a>
          </p>
          <p>
            Powered by{" "}
            <a
              href="https://liveuptothehype.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-150 ease-in-out hover:text-[#f20c2e] font-medium"
            >
              Hypertek Solutions LLC
            </a>{" "}
            | Live up to the hype!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
