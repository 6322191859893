import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { CheckPayment, CreateIntent } from "../actions/auth";

export default function CheckoutForm({ amount, invoiceId, nextStep }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [intent, setIntent] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    CreateIntent({ amount, invoiceId }).then((res) => {
      setClientSecret(res.data.clientSecret);
      setIntent(res.data.intent);
    });
  }, []);
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      CheckPayment({ intent: intent })
        .then((res) => {
          nextStep();
        })
        .catch((err) => {
          //
        });
    }
  };
  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="w-[600px] h-full"
    >
      <CardElement
        id="card-element"
        options={{}}
        onChange={handleChange}
        className="border border-black p-3"
      />
      <div className="w-full my-4 flex flex-row justify-center items-center">
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
          className="px-4 py-1 border border-black"
        >
          <span id="button-text">
            {processing ? "Processing..." : "Submit Payment"}
          </span>
        </button>
      </div>
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      <p
        className={
          succeeded
            ? "result-message w-full text-center"
            : "result-message hidden"
        }
      >
        Payment successful! We will send you a receipt and a confirmations
        shortly.
      </p>
    </form>
  );
}
