import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { GetUser } from "./actions/auth";
import { Toaster } from "react-hot-toast";
import CoreLayout from "./layouts/coreLayout";
import Login from "./views/login";
import Invite from "./views/invite";
import SignatureDisclosure from "./views/signatureDisclosure";
import HardwareRequirements from "./views/hardwareRequirements";
import ContactUs from "./views/contactUs";
import PayInvoice from "./views/payInvoice";

const App = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      GetUser()
        .then((response) => {
          authDispatch({
            type: "valid-loggin",
            payload: {
              token: localStorage.getItem("auth-token"),
              role: response.data.role,
              user: response.data,
            },
          });
          setLoading(false);
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return !loading ? (
    <div className="w-full h-screen overflow-x-hidden">
      <Toaster
        position="top-right"
        toastOptions={{
          className: "",
          style: {
            borderRadius: 0,
            fontFamily: "Lato",
          },
        }}
      />
      <Routes>
        <Route
          path="login"
          element={<Login authDispatch={authDispatch} authState={authState} />}
        />
        <Route
          path="signatureDisclosure"
          element={
            <SignatureDisclosure
              authDispatch={authDispatch}
              authState={authState}
            />
          }
        />
        <Route
          path="hardwareRequirements"
          element={
            <HardwareRequirements
              authDispatch={authDispatch}
              authState={authState}
            />
          }
        />
        <Route
          path="contactUs"
          element={
            <ContactUs authDispatch={authDispatch} authState={authState} />
          }
        />
        <Route
          path="payInvoice"
          element={
            <PayInvoice authDispatch={authDispatch} authState={authState} />
          }
        />
        <Route
          path="invite/:id"
          element={<Invite authDispatch={authDispatch} authState={authState} />}
        />
        <Route
          path="/*"
          element={
            authState.isAuth ? (
              <CoreLayout authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </div>
  ) : null;
};

export default App;
