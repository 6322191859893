import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import hitechLogo2 from "../assets/hitechLogo2.png";
import paidStamp from "../assets/paidStamp.png";

const currencyFormat = (val) => {
  let formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(val);
  return formatted;
};

export const GenerateQuotePDF = async (data, detail) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 315.12;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hitechLogo2, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
    doc.setDrawColor("#000000");
    doc.setLineWidth(0.8);
    doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
    if (data.quote.quoteStatus === "declined") {
      doc.setFontSize(18);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("#ff0000");
      doc.text("VOID - REJECTED", pageWidth / 2, 26, {
        align: "center",
      });
      doc.text("VOID - REJECTED", pageWidth / 2, 755, {
        align: "center",
      });
      doc.setDrawColor("#000000");
    }
    doc.setLineWidth(2);
    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
      align: "center",
    });
    doc.text("Augusta, GA 30906", 254, 104, {
      align: "center",
    });
    doc.text("Tel: (706) 790-8111", 254, 115, {
      align: "center",
    });
    doc.text("Fax: (706) 790-4368", 254, 126, {
      align: "center",
    });
    doc.text("1182 Edgefield Road", 360, 93, {
      align: "center",
    });
    doc.text("North Augusta, SC 29841", 360, 104, {
      align: "center",
    });
    doc.text("Tel: (803) 613-0101", 360, 115, {
      align: "center",
    });
    doc.text("Fax: (803) 613-0323", 360, 126, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.line(50, 135, pageWidth - 50, 135);
    doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
      align: "center",
    });
    doc.line(50, 155, pageWidth - 50, 155);
    doc.setFontSize(9);
    doc.text(data.customer.company.toUpperCase(), 50, 170, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.text(data.customer.arData.name.toUpperCase(), 50, 181, {
      align: "left",
    });
    let custAddr = data.customer.arData.address.toUpperCase();
    if (data.customer.arData.address2 && data.customer.arData.address2.length > 0) {
      custAddr += ", " + data.customer.arData.address2.toUpperCase();
    }
    doc.text(custAddr, 50, 192, {
      align: "left",
    });
    doc.text(`${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`, 50, 203, {
      align: "left",
    });
    doc.text(data.customer.arData.email.toUpperCase(), 50, 214, {
      align: "left",
    });
    doc.text(`Date of Estimate: ${moment(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
      align: "right",
    });
    if (data.quote.quoteStatus === "declined") {
      doc.text(`Estimate Rejected On: ${moment(data.quote.rejectedOn).format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
    } else if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.text(`Estimate Approved On: ${moment(data.quote.acceptance.approvedDate).format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
    } else {
      doc.text(`Estimate Expires: ${moment(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
    }
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
      align: "center",
    });
    doc.line(50, 237, pageWidth - 50, 237);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(9);
    doc.text(data.quote.description.toUpperCase(), pageWidth / 2, 250, {
      align: "center",
    });
    let quoteDetails = doc.splitTextToSize(data.quote.details, 420);
    let x = 261;
    doc.setFont("Helvetica", "normal");
    for (let i = 0; i < quoteDetails.length; i++) {
      doc.text(quoteDetails[i].toUpperCase(), pageWidth / 2, x, {
        align: "center",
      });
      x += 11;
    }
    x -= 4;
    doc.line(50, x, pageWidth - 50, x);
    x += 6;
    doc.line(50, x, pageWidth - 50, x);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    if (detail === "none") {
      x += 50;
      doc.text("TOTAL ESTIMATE", pageWidth / 2, x, {
        align: "center",
      });
      x += 14;
      let formattedTotal = currencyFormat(data.quote.total);
      doc.text(`${formattedTotal}`, pageWidth / 2, x, {
        align: "center",
      });
      doc.setLineWidth(0.8);
      let txWid = doc.getTextWidth(`${formattedTotal}`);
      x += 2;
      doc.line(pageWidth / 2 - txWid / 2 - 3, x, pageWidth / 2 + txWid / 2 + 3, x);
      doc.setLineWidth(1);
      x += 48;
    } else if (detail === "group") {
      x += 20;
      let laborTotal = currencyFormat(data.quote.laborTotal);
      let partsTotal = currencyFormat(data.quote.partsTotal);
      let gogTotal = currencyFormat(data.quote.gogTotal);
      let miscTotal = currencyFormat(data.quote.miscFees);
      let estimateTotal = currencyFormat(data.quote.total);

      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Labor Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${laborTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Parts Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${partsTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Gas, Oil & Grease Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${gogTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Misc. Fees Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${miscTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Estimate Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.text(`${estimateTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });

      x += 20;
    } else if (detail === "all") {
      x += 20;
      let laborTotal = 0;
      let partsTotal = 0;
      let gogTotal = 0;
      let miscTotal = 0;
      let shopFeeTotal = 0;
      let estimateTotal = 0;

      let laborItems = [];
      let partsItems = [];
      let miscItems = [];

      for (let i = 0; i < data.quote.labor.length; i++) {
        laborTotal += data.quote.labor[i].extPrice;
        let el = data.quote.labor[i];
        laborItems.push([
          el.hours,
          el.rate,
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.perHour),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      }
      for (let i = 0; i < data.quote.parts.length; i++) {
        if (data.quote.parts[i].category !== "gog") {
          partsTotal += data.quote.parts[i].extPrice;
        } else {
          gogTotal += data.quote.parts[i].extPrice;
        }
        let el = data.quote.parts[i];
        partsItems.push([
          el.quantity,
          el.partNo,
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.pricePerPart),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      }
      for (let i = 0; i < data.quote.misc.length; i++) {
        let el = data.quote.misc[i];
        if (el.miscType === "service") {
          laborTotal += el.chargeOutPrice;
          laborItems.push([
            el.quantity,
            "SPEC-SERV",
            el.description,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice / el.quantity),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice),
          ]);
        } else if (el.miscType === "part") {
          partsTotal += el.chargeOutPrice;
          partsItems.push([
            el.quantity,
            "SPEC-PART",
            el.description,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice / el.quantity),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice),
          ]);
        }
      }
      for (let i = 0; i < data.quote.freight.length; i++) {
        miscTotal += data.quote.freight[i].freightChargeOut;
        let el = data.quote.freight[i];
        miscItems.push([
          "1",
          "FREIGHT FEE",
          "Freight Charge",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.freightChargeOut),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.freightChargeOut),
        ]);
      }
      for (let i = 0; i < data.quote.loadbank.length; i++) {
        miscTotal += data.quote.loadbank[i].unitCharge;
        let el = data.quote.loadbank[i];
        miscItems.push([
          el.quantity,
          "LOADBANK FEE",
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
        ]);
      }
      for (let i = 0; i < data.quote.parking.length; i++) {
        miscTotal += data.quote.parking[i].totalCharge;
        let el = data.quote.parking[i];
        miscItems.push([
          el.quantity,
          el.parkingType[0].toUpperCase() + el.parkingType.slice(1) + " FEE",
          el.increment[0].toUpperCase() + el.increment.slice(1) + " " + el.parkingType[0].toUpperCase() + el.parkingType.slice(1) + " Fee",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.totalCharge),
        ]);
      }
      for (let i = 0; i < data.quote.shopFee.length; i++) {
        shopFeeTotal += data.quote.shopFee[i].chargeOutPrice;
      }
      estimateTotal = laborTotal + partsTotal + gogTotal + miscTotal + shopFeeTotal;
      laborTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(laborTotal);
      partsTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(partsTotal);
      gogTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(gogTotal);
      miscTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(miscTotal);
      shopFeeTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(shopFeeTotal);
      estimateTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(estimateTotal);
      if (laborItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Labor ", 60, x, {
          align: "left",
        });

        doc.autoTable({
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Hours", styles: { halign: "left" } },
              { content: "Labor Rate", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Per Hour", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: laborItems,
          margin: { top: x + 10, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 15;
      }
      if (partsItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Parts ", 60, x, {
          align: "left",
        });
        x += 10;

        doc.autoTable({
          startY: x,
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Quantity", styles: { halign: "left" } },
              { content: "Part No", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Unit Price", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: partsItems,
          margin: { top: 0, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 20;
      }
      if (miscItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Misc. Fees ", 60, x, {
          align: "left",
        });
        x += 10;

        doc.autoTable({
          startY: x,
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Quantity", styles: { halign: "left" } },
              { content: "Fee Code", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Unit Price", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: miscItems,
          margin: { top: 0, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 20;
      }
      if (x > 690) {
        doc.addPage();
        let imgWidth = 315.12;
        let imgXPos = pageWidth / 2 - imgWidth / 2;
        doc.addImage(hitechLogo2, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
        doc.setDrawColor("#000000");
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.setLineWidth(0.8);
        if (data.quote.quoteStatus === "declined") {
          doc.setFontSize(18);
          doc.setFont("Helvetica", "bold");
          doc.setTextColor("#ff0000");
          doc.text("VOID - REJECTED", pageWidth / 2, 26, {
            align: "center",
          });
          doc.text("VOID - REJECTED", pageWidth / 2, 755, {
            align: "center",
          });
          doc.setDrawColor("#000000");
        }
        doc.setTextColor("#000000");
        doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
        doc.setLineWidth(2);
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(8);
        doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
          align: "center",
        });
        doc.text("Augusta, GA 30906", 254, 104, {
          align: "center",
        });
        doc.text("Tel: (706) 790-8111", 254, 115, {
          align: "center",
        });
        doc.text("Fax: (706) 790-4368", 254, 126, {
          align: "center",
        });
        doc.text("1182 Edgefield Road", 360, 93, {
          align: "center",
        });
        doc.text("North Augusta, SC 29841", 360, 104, {
          align: "center",
        });
        doc.text("Tel: (803) 613-0101", 360, 115, {
          align: "center",
        });
        doc.text("Fax: (803) 613-0323", 360, 126, {
          align: "center",
        });
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.line(50, 135, pageWidth - 50, 135);
        doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
          align: "center",
        });
        doc.line(50, 155, pageWidth - 50, 155);
        doc.setFontSize(9);
        doc.text(data.customer.company.toUpperCase(), 50, 170, {
          align: "left",
        });
        doc.setFont("Helvetica", "normal");
        doc.text(data.customer.arData.name.toUpperCase(), 50, 181, {
          align: "left",
        });
        let custAddr = data.customer.arData.address.toUpperCase();
        if (data.customer.arData.address2 && data.customer.arData.address2.length > 0) {
          custAddr += ", " + data.customer.arData.address2.toUpperCase();
        }
        doc.text(custAddr, 50, 192, {
          align: "left",
        });
        doc.text(`${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`, 50, 203, {
          align: "left",
        });
        doc.text(data.customer.arData.email.toUpperCase(), 50, 214, {
          align: "left",
        });
        doc.text(`Date of Estimate: ${moment(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
          align: "right",
        });
        if (data.quote.quoteStatus === "declined") {
          doc.text(`Estimate Rejected On: ${moment(data.quote.rejectedOn).format("MM/DD/YYYY")}`, 562, 181, {
            align: "right",
          });
        } else {
          doc.text(`Estimate Expires: ${moment(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
            align: "right",
          });
        }
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(12);
        doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
          align: "center",
        });
        doc.line(50, 237, pageWidth - 50, 237);
        x = 250;
      }
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Labor Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${laborTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Parts Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${partsTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Gas, Oil & Grease Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${gogTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Misc. Fees Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${miscTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Shop Fee Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${shopFeeTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Estimate Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.text(`${estimateTotal}`, pageWidth - 70, x, {
        align: "right",
      });

      x += 20;
    }
    doc.line(50, x, pageWidth - 50, x);
    if (x > 690) {
      doc.addPage();
      let imgWidth = 315.12;
      let imgXPos = pageWidth / 2 - imgWidth / 2;
      doc.addImage(hitechLogo2, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.setLineWidth(0.8);
      if (data.quote.quoteStatus === "declined") {
        doc.setFontSize(18);
        doc.setFont("Helvetica", "bold");
        doc.setTextColor("#ff0000");
        doc.text("VOID - REJECTED", pageWidth / 2, 26, {
          align: "center",
        });
        doc.text("VOID - REJECTED", pageWidth / 2, 755, {
          align: "center",
        });
        doc.setDrawColor("#000000");
      }
      doc.setTextColor("#000000");
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.text(data.customer.company.toUpperCase(), 50, 170, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(data.customer.arData.name.toUpperCase(), 50, 181, {
        align: "left",
      });
      let custAddr = data.customer.arData.address.toUpperCase();
      if (data.customer.arData.address2 && data.customer.arData.address2.length > 0) {
        custAddr += ", " + data.customer.arData.address2.toUpperCase();
      }
      doc.text(custAddr, 50, 192, {
        align: "left",
      });
      doc.text(`${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`, 50, 203, {
        align: "left",
      });
      doc.text(data.customer.arData.email.toUpperCase(), 50, 214, {
        align: "left",
      });
      doc.text(`Date of Estimate: ${moment(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
        align: "right",
      });
      if (data.quote.quoteStatus === "declined") {
        doc.text(`Estimate Rejected On: ${moment(data.quote.rejectedOn).format("MM/DD/YYYY")}`, 562, 181, {
          align: "right",
        });
      } else {
        doc.text(`Estimate Expires: ${moment(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
          align: "right",
        });
      }
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
        align: "center",
      });
      doc.line(50, 237, pageWidth - 50, 237);
      x = 250;
    }
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    x += 12;
    for (let i = 0; i < data.quote.footer.length; i++) {
      doc.text(data.quote.footer[i].toUpperCase(), pageWidth / 2, x, {
        align: "center",
      });
      x += 12;
    }
    doc.text("*** ALL ESTIMATES GOOD FOR 30 DAYS FROM ISSUANCE DATE ***", pageWidth / 2, x, {
      align: "center",
    });
    x += 12;
    let terms = "";
    if (data.quote.paymentTerms === "net10") {
      terms = "NET 10";
    } else if (data.quote.paymentTerms === "net20") {
      terms = "NET 20";
    } else if (data.quote.paymentTerms === "net30") {
      terms = "NET 30";
    } else if (data.quote.paymentTerms === "collectOnDelivery") {
      terms = "COLLECT ON DELIVERY";
    } else if (data.quote.paymentTerms === "dueOnReceipt") {
      terms = "DUE ON RECEIPT";
    } else if (data.quote.paymentTerms === "prePay") {
      terms = "PAY UPFRONT";
    }
    doc.setFont("Helvetica", "bold");
    doc.text(`*** TERMS: ${terms} ***`, pageWidth / 2, x, {
      align: "center",
    });
    x += 6;
    doc.setLineWidth(2);
    doc.line(50, x, pageWidth - 50, x);
    if (x > 520) {
      doc.addPage();
      let imgWidth = 315.12;
      let imgXPos = pageWidth / 2 - imgWidth / 2;
      doc.addImage(hitechLogo2, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("#ff0000");
      doc.setLineWidth(0.8);
      if (data.quote.quoteStatus === "declined") {
        doc.setFontSize(18);
        doc.setFont("Helvetica", "bold");
        doc.setTextColor("#ff0000");
        doc.text("VOID - REJECTED", pageWidth / 2, 26, {
          align: "center",
        });
        doc.text("VOID - REJECTED", pageWidth / 2, 755, {
          align: "center",
        });
        doc.setDrawColor("#000000");
      }
      doc.setTextColor("#000000");
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.text(data.customer.company.toUpperCase(), 50, 170, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(data.customer.arData.name.toUpperCase(), 50, 181, {
        align: "left",
      });
      let custAddr = data.customer.arData.address.toUpperCase();
      if (data.customer.arData.address2 && data.customer.arData.address2.length > 0) {
        custAddr += ", " + data.customer.arData.address2.toUpperCase();
      }
      doc.text(custAddr, 50, 192, {
        align: "left",
      });
      doc.text(`${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`, 50, 203, {
        align: "left",
      });
      doc.text(data.customer.arData.email.toUpperCase(), 50, 214, {
        align: "left",
      });
      doc.text(`Date of Estimate: ${moment(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
        align: "right",
      });
      if (data.quote.quoteStatus === "declined") {
        doc.text(`Estimate Rejected On: ${moment(data.quote.rejectedOn).format("MM/DD/YYYY")}`, 562, 181, {
          align: "right",
        });
      } else {
        doc.text(`Estimate Expires: ${moment(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
          align: "right",
        });
      }
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
        align: "center",
      });
      doc.line(50, 237, pageWidth - 50, 237);
      x = 250;
    }
    x += 12;
    doc.setFontSize(8);
    doc.text(`Customer Acceptance:`, 70, x, {
      align: "left",
    });
    let xPos = 70 + doc.getTextWidth("Customer Acceptance:") + 10;
    doc.setFont("Helvetica", "normal");
    let acceptanceTerms = `By approval below, we accept the above bill of material, price, and terms, as described in Hi-Tech Power Systems Estimate # ${data.quote.quoteNo}, and request that the order be released for production.`;
    var splitTitle = doc.splitTextToSize(acceptanceTerms, pageWidth - 220);
    for (let i = 0; i < splitTitle.length; i++) {
      doc.text(splitTitle[i], xPos, x, {
        align: "left",
      });
      x += 10;
    }
    x += 40;
    doc.setLineWidth(1);
    let sigWid = pageWidth / 3;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.setFontSize(7);
    doc.text("Company Name", 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.companyName, 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    doc.text("Date", pageWidth - sigWid - 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(moment(data.quote.acceptance.approvedDate).format("MM/DD/YYYY"), pageWidth - sigWid - 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    x += 40;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.text("Approved By (Signature)", 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      if (data.quote.acceptance.signatureImage.length > 0) {
        let signatureURL = data.quote.acceptance.signatureImage;
        let dataUrl = await getBase64FromUrl(signatureURL);
        let imgWid = 111;
        let left = 60 + (sigWid - imgWid) / 2;
        doc.addImage(dataUrl, "PNG", left, x - 50, imgWid, 50, "Signature", "NONE", 0);
      }
    }
    doc.text("Title", pageWidth - sigWid - 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.title, pageWidth - sigWid - 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    x += 40;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.text("Approved By (Print Name)", 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.signedBy, 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    doc.text("Purchase Order #", pageWidth - sigWid - 60, x + 8, {
      align: "left",
    });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.poNumber, pageWidth - sigWid - 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    if (pageCount > 1) {
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        pageWidth = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.getHeight();
        doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2, pageHeight - 18, {
          align: "center",
        });
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};
const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const GenerateInvoicePDF = (data, detail) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let imgWidth = 290;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hitechLogo2, "PNG", imgXPos, 30, imgWidth, 78.25, "hitechLogo", "NONE", 0);
    doc.setDrawColor("#000000");
    doc.setLineWidth(2);
    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
      align: "center",
    });
    doc.text("Augusta, GA 30906", 254, 104, {
      align: "center",
    });
    doc.text("Tel: (706) 790-8111", 254, 115, {
      align: "center",
    });
    doc.text("Fax: (706) 790-4368", 254, 126, {
      align: "center",
    });
    doc.text("1182 Edgefield Road", 360, 93, {
      align: "center",
    });
    doc.text("North Augusta, SC 29841", 360, 104, {
      align: "center",
    });
    doc.text("Tel: (803) 613-0101", 360, 115, {
      align: "center",
    });
    doc.text("Fax: (803) 613-0323", 360, 126, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.line(50, 135, pageWidth - 50, 135);
    doc.text(data.invoice.invoiceNo, pageWidth / 2, 149, {
      align: "center",
    });
    doc.line(50, 155, pageWidth - 50, 155);
    doc.setFontSize(9);
    doc.text(data.customer.company.toUpperCase(), 50, 170, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.text(data.customer.arData.name.toUpperCase(), 50, 181, {
      align: "left",
    });
    let custAddr = data.customer.arData.address.toUpperCase();
    if (data.customer.arData.address2 && data.customer.arData.address2.length > 0) {
      custAddr += ", " + data.customer.arData.address2.toUpperCase();
    }
    doc.text(custAddr, 50, 192, {
      align: "left",
    });
    doc.text(`${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`, 50, 203, {
      align: "left",
    });
    doc.text(data.customer.arData.email.toUpperCase(), 50, 214, {
      align: "left",
    });
    doc.text(`Invoice Date: ${moment(data.invoice.invoiceDate).format("MM/DD/YYYY")}`, 562, 170, {
      align: "right",
    });
    doc.text(`Due Date: ${moment(data.invoice.dueDate).format("MM/DD/YYYY")}`, 562, 181, {
      align: "right",
    });
    doc.text(`PO Number: ${data.invoice.customerPo && data.invoice.customerPo.length > 0 ? data.invoice.customerPo : "N/A"}`, 562, 192, {
      align: "right",
    });
    let terms = {
      net10: "NET 10",
      net20: "NET 20",
      net30: "NET 30",
      collectOnDelivery: "COLLECT ON DELIVERY",
      dueOnReceipt: "DUE ON RECEIPT",
      prePay: "PAY UPFRONT",
    };
    doc.text(`Payment Terms: ${terms[data.invoice.paymentTerms]}`, 562, 203, {
      align: "right",
    });
    let status = {
      sent: "UNPAID",
      ready: "UNPAID",
      paid: "PAID",
      overdue: "OVERDUE",
      void: "VOID",
    };
    doc.text(`Invoice Status: ${status[data.invoice.invoiceStatus]}`, 562, 214, {
      align: "right",
    });
    doc.line(50, 225, pageWidth - 50, 225);
    let y = 241;

    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text("JOB DESCRIPTION", 50, y, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    y += 11;
    doc.text(data.invoice.jobDescription, 50, y, {
      align: "left",
      maxWidth: pageWidth - 100,
    });
    var dim = doc.splitTextToSize(data.invoice.jobDescription, pageWidth - 100, {
      fontSize: 9,
    });
    y += dim.length * 9;
    y += 11;

    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text("DETAILS", 50, y, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    y += 11;
    doc.text(data.invoice.jobDetails, 50, y, {
      align: "left",
      maxWidth: pageWidth - 100,
    });
    var dim2 = doc.splitTextToSize(data.invoice.jobDetails, pageWidth - 100, {
      fontSize: 9,
    });
    y += dim2.length * 9;
    y += 11;
    doc.line(50, y, pageWidth - 50, y);
    y += 11;

    let partsItems = [];
    let gogItems = [];
    let servicesItems = [];

    for (let i = 0; i < data.invoice.parts.length; i++) {
      if (data.invoice.parts[i].category !== "gog") {
        let el = data.invoice.parts[i];
        partsItems.push([
          el.quantity,
          el.partNo,
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.pricePerPart),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      } else {
        let el = data.invoice.parts[i];
        gogItems.push([
          el.quantity,
          el.partNo,
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.pricePerPart),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      }
    }
    for (let i = 0; i < data.invoice.misc.length; i++) {
      let el = data.invoice.misc[i];
      if (el.miscType === "part") {
        partsItems.push([
          el.quantity,
          "MISC-PART",
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.chargeOutPrice / el.quantity),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.chargeOutPrice),
        ]);
      } else {
        servicesItems.push([
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.chargeOutPrice),
        ]);
      }
    }

    if (partsItems.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("PARTS", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#3d4044",
          textColor: "#ffffff",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "left", cellWidth: "auto" },
          2: { cellWidth: "auto" },
          3: { halign: "right", cellWidth: "auto" },
          4: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Quantity", styles: { halign: "left" } },
            { content: "Part No", styles: { halign: "left" } },
            { content: "Description", styles: { halign: "left" } },
            { content: "Unit Price", styles: { halign: "right" } },
            { content: "Ext. Price", styles: { halign: "right" } },
          ],
        ],
        body: partsItems,
        margin: { top: 0, left: 50, right: 50, bottom: 15 },
        theme: "grid",
      });
      y = doc.lastAutoTable.finalY + 20;
    }

    if (gogItems.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("GAS, OIL & GREASE", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#3d4044",
          textColor: "#ffffff",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "left", cellWidth: "auto" },
          2: { cellWidth: "auto" },
          3: { halign: "right", cellWidth: "auto" },
          4: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Quantity", styles: { halign: "left" } },
            { content: "Part No", styles: { halign: "left" } },
            { content: "Description", styles: { halign: "left" } },
            { content: "Unit Price", styles: { halign: "right" } },
            { content: "Ext. Price", styles: { halign: "right" } },
          ],
        ],
        body: gogItems,
        margin: { top: 0, left: 50, right: 50, bottom: 15 },
        theme: "grid",
      });
      y = doc.lastAutoTable.finalY + 20;
    }

    if (servicesItems.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("SERVICES", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#3d4044",
          textColor: "#ffffff",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Description", styles: { halign: "left" } },
            { content: "Ext. Price", styles: { halign: "right" } },
          ],
        ],
        body: servicesItems,
        margin: { top: 0, left: 50, right: 50, bottom: 15 },
        theme: "grid",
      });
      y = doc.lastAutoTable.finalY + 20;
    }

    if (data.invoice.equipment.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("EQUIPMENT", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      //EQUIPMENT
      let raw = [];
      for (let i = 0; i < data.invoice.equipment.length; i++) {
        let el = data.invoice.equipment[i];
        let types = {
          engine: "Engine",
          generator: "Generator",
          pressureWasher: "Pressure Washer",
          rv: "RV",
          tractor: "Tractor",
          tractorTrailer: "Tractor Trailer",
          welder: "Welder",
          other: "Other",
        };
        let fuels = {
          diesel: "Diesel",
          gasoline: "Gasoline",
          naturalGas: "Natural Gas",
          propane: "Propane",
          biFuel: "Bi-Fuel",
          electricity: "Electricity",
          other: "Other",
        };
        raw.push({
          "Equip. Type": types[el.equipmentType],
          "Equip. ID": el.customerEquipId && el.customerEquipId.length > 0 ? el.customerEquipId : "N/A",
          Location: el.location && el.location.length > 0 ? el.location : "N/A",
        });
        raw.push({
          "Fuel Type": fuels[el.fuelType],
          Make: el.details.make && el.details.make.length > 0 ? el.details.make : "N/A",
          Model: el.details.model && el.details.model.length > 0 ? el.details.model : "N/A",
        });
        raw.push({
          "Spec No": el.details.specNo && el.details.specNo.length > 0 ? el.details.specNo : "N/A",
          "Serial No": el.details.serialNo && el.details.serialNo.length > 0 ? el.details.serialNo : "N/A",
          "ATS Make": el.ats.make && el.ats.make.length > 0 ? el.ats.make : "N/A",
        });
        raw.push({
          "ATS Model": el.ats.model && el.ats.model.length > 0 ? el.ats.model : "N/A",
          "ATS Serial": el.ats.serialNo && el.ats.serialNo.length > 0 ? el.ats.serialNo : "N/A",
          "ATS Size": el.ats.size && el.ats.size.length > 0 ? el.ats.size : "N/A",
        });
        raw.push({
          "Engine Make": el.engine.make && el.engine.make.length > 0 ? el.engine.make : "N/A",
          "Engine Model": el.engine.model && el.engine.model.length > 0 ? el.engine.model : "N/A",
          "Engine Serial": el.engine.serialNo && el.engine.serialNo.length > 0 ? el.engine.serialNo : "N/A",
        });
        raw.push({
          "Engine Code": el.engine.codeNo && el.engine.codeNo.length > 0 ? el.engine.codeNo : "N/A",
          "Miles/Hours": el.miles ? el.miles : "N/A",
          Power: el.power && el.pwrUnit && el.pwrUnit.length > 0 ? el.power + " " + el.pwrUnit : "N/A",
        });
      }
      let body = [];

      for (var i = 0; i < raw.length; i++) {
        var row = [];
        for (var key in raw[i]) {
          row.push({ content: `${key}: ` + raw[i][key] });
        }
        if (i % 6 === 0 && i > 0) {
          body.push([
            {
              content: "",
              // rowSpan: 1,
              colSpan: 3,
              styles: { halign: "center", valign: "top" },
            },
          ]);
        }
        body.push(row);
      }

      doc.autoTable({
        startY: y,
        head: [
          [
            {
              content: "Equipment",
              colSpan: 3,
              styles: { halign: "center", fillColor: "#3d4044" },
            },
          ],
        ],
        body: body,
        rowPageBreak: "auto",
        theme: "grid",
        bodyStyles: { valign: "top" },
        styles: {
          overflow: "linebreak",
          // columnWidth: "100",
          font: "Helvetica",
          fontSize: 9,
          cellPadding: 4,
          overflowColumns: "linebreak",
        },
        margin: { left: 50, right: 50 },
      });
      y = doc.lastAutoTable.finalY + 20;
    }

    doc.line(50, y, pageWidth - 50, y);
    y += 16;
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    let startY = y;
    doc.text("PLEASE REMIT PAYMENT TO:", 50, y, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    y += 11;
    doc.text("Hi-Tech Power Systems", 50, y, {
      align: "left",
    });
    doc.setFontSize(9);
    y += 11;
    doc.text("2479 Doug Barnard Pkwy", 50, y, {
      align: "left",
    });
    y += 11;
    doc.text("Augusta, GA 30906", 50, y, {
      align: "left",
    });
    y += 11;
    doc.text("(706) 790-8111", 50, y, {
      align: "left",
    });
    y += 11;
    if (data.invoice.invoiceStatus === "paid") {
      doc.addImage(paidStamp, "PNG", pageWidth / 4 - 60, y, 120, 120, "paidStamp", "NONE", 0);
    }
    doc.autoTable({
      startY: startY,
      headStyles: {
        fillColor: "#3d4044",
        textColor: "#ffffff",
      },
      columnStyles: {
        0: { halign: "left", cellWidth: "auto" },
        1: { halign: "right", cellWidth: "auto" },
      },
      styles: {
        fontSize: 9,
        font: "Helvetica",
        minCellHeight: 12,
        cellPadding: 4,
      },
      head: [],
      body: [
        [
          "LABOR",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.laborTotal),
        ],
        [
          "PARTS",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.partsTotal),
        ],
        [
          "GAS, OIL & GREASE",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.gogTotal),
        ],
        [
          "PARKING/STORAGE",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.parkingTotal),
        ],
        [
          "LOADBANK",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.loadbankTotal),
        ],
        [
          "SERVICES",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.servicesTotal),
        ],
        [
          "FREIGHT",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.freightTotal),
        ],
        [
          "MISC. SHOP",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.shopFeeTotal),
        ],
        [
          "SUBTOTAL",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.subtotal),
        ],
        [
          "TAX",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.tax),
        ],
        [
          "TOTAL",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.invoice.total),
        ],
      ],
      margin: { top: 0, left: pageWidth / 2, right: 50, bottom: 15 },
      theme: "grid",
      tableWidth: (pageWidth - 100) / 2,
    });
    y = doc.lastAutoTable.finalY + 20;
    doc.setDrawColor("#3d4044");
    doc.setTextColor("#3d4044");
    doc.line(50, y, pageWidth - 50, y);
    y += 16;
    doc.setFontSize(8);
    let noWarranty =
      "UNLESS OTHERWISE PROVIDED BY LAW, THE SELLER (ABOVE NAMED DEALERSHIP) HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTY OF MERCHANT-ABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND NEITHER ASSUMES NOR AUTHORIZES ONY OTHER PERSON TO ASSUME FOR IT ANY LIABILITY IN CONNECTION WITH THE SALE OF SAID PRODUCTS.";
    doc.text(noWarranty, pageWidth / 2, y, {
      align: "center",
      maxWidth: pageWidth - 100,
    });
    var dim3 = doc.splitTextToSize(noWarranty, pageWidth - 100, {
      fontSize: 8,
    });
    y += dim3.length * 8;
    y += 11;
    doc.line(50, y, pageWidth - 50, y);

    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (var pg = 0; pg <= pageCount; pg++) {
      doc.setPage(pg);
      doc.text("Page " + String(pg) + " of " + String(pageCount), doc.internal.pageSize.width - 50, 765, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};
