import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useSearchParams, useNavigate } from "react-router-dom";
import { GetInvoice } from "../actions/auth";
import moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";

const stripePromise = loadStripe("pk_test_51MUWGqG0xROCSBeSIzOmUcAVlgJgoSAj1JOmnoqkuX94fi2RMiujA0wdi9hCuWS13MXnjzCnvzS7VOy5nG1wG075009cn7krJB");

const PayInvoice = () => {
  const [paid, setPaid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState({
    invoiceNo: "",
    customerName: "",
    laborTotal: 0,
    partsTotal: 0,
    miscTotal: 0,
    subtotal: 0,
    tax: 0,
    total: 0,
  });
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      setLoading(true);
      const invoiceId = searchParams.get("invoiceId");
      GetInvoice({ invoiceId })
        .then((res) => {
          setInvoice(res.data);
          if (res.data.invoiceStatus === "paid") {
            setPaid(true);
            setStep(2);
          }
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setTimeout(() => {
            navigate("/");
          }, 700);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const currencyFormat = (value) => {
    let formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
    return formatted;
  };

  const creditCardFee = () => {
    let total = parseFloat((invoice.total * 0.029).toFixed(2));
    total += 0.3;
    return currencyFormat(total);
  };

  const nextStep = () => {
    setStep(2);
  };

  const renderStep = () => {
    if (step === 0) {
      return (
        <div className="flex flex-col items-center justify-center w-full">
          <h1 className="text-2xl font-semibold uppercase font-body">Invoice {invoice.invoiceNo ?? ""}</h1>
          <p>Prepared for {invoice.customerName ?? ""}</p>
          <div className="w-full my-10 border-t border-gray-200" />
          <div className="flex flex-col items-center justify-between w-1/3 gap-1">
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Labor</p>
              <p>{currencyFormat(invoice.laborTotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Parts</p>
              <p>{currencyFormat(invoice.partsTotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Misc. Shop</p>
              <p>{currencyFormat(invoice.miscTotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 pt-1 font-medium uppercase border-t border-gray-200 font-body">
              <p>Subtotal</p>
              <p>{currencyFormat(invoice.subtotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Tax</p>
              <p>{currencyFormat(invoice.tax ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 pt-1 font-medium uppercase border-t border-gray-200 font-body">
              <p>Total</p>
              <p>{currencyFormat(invoice.total ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-1 pt-2 uppercase font-body">
              <p className="text-[0.6rem] text-gray-600 text-center">A 2.9% fee will be applied to if you choose to pay with a credit card.</p>
            </div>
          </div>
          <div className="w-full my-10 border-t border-gray-200" />
          <div className="flex flex-row items-center justify-end w-full">
            <button
              className="px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm font-body hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-transparent disabled:hidden"
              onClick={() => setStep(1)}
              disabled={paid}
            >
              Proceed To Payment
            </button>
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="flex flex-col items-center justify-center w-full">
          <Elements stripe={stripePromise}>
            <CheckoutForm amount={getAmount()} invoiceId={invoice.invoiceId} nextStep={() => nextStep()} />
          </Elements>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="flex flex-col items-center justify-center w-full">
          <h1 className="text-2xl font-semibold uppercase font-body">Invoice {invoice.invoiceNo ?? ""} | PAID</h1>
          <p>Prepared for {invoice.customerName ?? ""}</p>
          <div className="w-full my-10 border-t border-gray-200" />
          <div className="flex flex-col items-center justify-between w-1/2 gap-1">
            <p className="text-xl text-center font-body">Thank you for your payment. We appreciate your business!</p>
            <p className="text-sm text-center font-body">You will receive a receipt containing a payment confirmation shortly.</p>
          </div>
          <div className="flex flex-col items-center justify-between w-1/3 gap-1 pt-5 mt-5 border-t border-gray-300">
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Labor</p>
              <p>{currencyFormat(invoice.laborTotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Parts</p>
              <p>{currencyFormat(invoice.partsTotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Misc. Shop</p>
              <p>{currencyFormat(invoice.miscTotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 pt-1 font-medium uppercase border-t border-gray-200 font-body">
              <p>Subtotal</p>
              <p>{currencyFormat(invoice.subtotal ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 font-medium uppercase font-body">
              <p>Tax</p>
              <p>{currencyFormat(invoice.tax ?? 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-1 pt-1 font-medium uppercase border-t border-gray-200 font-body">
              <p>Total</p>
              <p>{currencyFormat(invoice.total ?? 0)}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  const getAmount = () => {
    let total = invoice.total;
    let fee = total * 0.029 + 0.3;
    let allTogether = parseFloat((total + fee).toFixed(2));
    return allTogether * 100;
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row items-center justify-center w-full bg-black min-h-14 h-14">
        <div className="w-full max-w-[1200px] flex flex-row justify-between items-center text-white h-14">
          <p className="text-2xl cursor-pointer font-display">Hi-Tech Power Systems</p>
          <div className="flex flex-row items-center justify-end gap-4">
            <p className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer">Contact Us</p>
            <p
              className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Client Portal
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center my-8 w-full max-w-[1200px] mx-auto items-center gap-2 flex-1">{renderStep()}</div>
      <div className="flex flex-row items-center justify-center w-full border-t border-gray-300">
        <div className="h-14 w-full max-w-[1200px] mx-auto flex flex-row justify-between items-center text-xs py-3">
          <p>
            All rights reserved © Copyright 1986 - {moment().format("YYYY")} |{" "}
            <a href="https://hitechpowersystems.com" target="_blank" rel="noreferrer" className="font-medium">
              Hi-Tech Power Systems
            </a>
          </p>
          <p>
            Powered by{" "}
            <a
              href="https://liveuptothehype.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-150 ease-in-out hover:text-[#f20c2e] font-medium"
            >
              Hypertek Solutions LLC
            </a>{" "}
            | Live up to the hype!
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayInvoice;
