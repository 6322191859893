import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Register } from "../actions/auth";
import validator from "validator";
import toast from "react-hot-toast";

const Invite = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const { id } = useParams();
  let navigate = useNavigate();

  const fieldChange = (e) => {
    let { name, value } = e.target;
    let tmp = data;
    tmp[name] = value;
    setData(tmp);
  };

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (id.length < 10) {
        navigate("/");
      } else if (authState.isAuth) {
        navigate("/");
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const submitForm = () => {
    let valid = true;
    if (!validator.isEmail(data.email)) {
      valid = false;
      toast.error("Please enter a valid email");
    }
    if (!validator.isMobilePhone(data.phoneNumber) && valid) {
      valid = false;
      toast.error("Please enter a valid phone number");
    }
    if (!validator.isStrongPassword(data.password) && valid) {
      valid = false;
      toast.error(
        "Please enter a password containing at least 8 characters, including at least 1 lowercase letter, 1 uppercase letter, 1 number, and one symbol."
      );
    }
    if (data.firstName.length < 2 && valid) {
      valid = false;
      toast.error("Please enter a valid first name");
    }
    if (data.lastName.length < 2 && valid) {
      valid = false;
      toast.error("Please enter a valid last name");
    }
    if (valid) {
      setLoading(true);
      let toSend = data;
      toSend.inviteId = id;
      Register(toSend)
        .then((res) => {
          let payload = {
            token: res.data.token,
            email: data.email,
            user: res.data.user,
          };
          toast.success("Account created Successfully!");
          toast.success("Redirecting you to the client portal...");
          localStorage.setItem("auth-token", res.data.token);
          authDispatch({
            type: "valid-loggin",
            payload: payload,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    }
  };

  return (
    <div className="w-full h-full flex flex-row justify-center items-center">
      <div className="w-[400px] h-[650px] flex flex-col justify-between shadow-2xl rounded-lg px-10 py-12 items-center">
        <div className="flex- flex-col justify-start items-center text-center">
          <h1 className="font-display text-2xl">Hi-Tech Power Systems</h1>
          <p className="font-body uppercase text-sm font-medium">
            Register to access the client portal
          </p>
        </div>
        <div className="flex flex-col justify-start items-center gap-2 w-full">
          {!loading ? (
            <div className="w-full">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-black font-body text-left w-full mt-2"
              >
                First Name
              </label>
              <div className="mt-1 text-left w-full">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                  onChange={(e) => fieldChange(e)}
                />
              </div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-black font-body text-left w-full mt-2"
              >
                Last Name
              </label>
              <div className="mt-1 text-left w-full">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                  onChange={(e) => fieldChange(e)}
                />
              </div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-black font-body text-left w-full mt-2"
              >
                Phone Number
              </label>
              <div className="mt-1 text-left w-full">
                <input
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                  onChange={(e) => fieldChange(e)}
                />
              </div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-black font-body text-left w-full mt-2"
              >
                Email
              </label>
              <div className="mt-1 text-left w-full">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                  onChange={(e) => fieldChange(e)}
                />
              </div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-black font-body text-left w-full mt-2"
              >
                Password
              </label>
              <div className="mt-1 text-left w-full">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                  onChange={(e) => fieldChange(e)}
                />
              </div>
            </div>
          ) : (
            <div className="w-full flex-1 justify-center items-center flex flex-col gap-4">
              <svg
                className="animate-spin h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25 stroke-black"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="#c9c9c9"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75 fill-black"
                  fill="#c9c9c9"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p className="text-sm font-medium text-center opacity-85 animate-pulse">
                Loading...
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-start items-start gap-2 w-full">
          <button
            className="mt-8 w-full bg-black text-white border border-black py-2 rounded-md transition-all ease-in-out duration-150 hover:text-black hover:bg-white cursor-pointer font-body tracking-wide font-bold disabled:hover:bg-gray-800 disabled:hover:text-white disabled:hover:border-gray-800 disabled:hover:cursor-not-allowed"
            onClick={() => submitForm()}
            disabled={loading}
          >
            {loading ? "PROCESSING..." : "ACCESS PORTAL"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invite;
