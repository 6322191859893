import { useNavigate } from "react-router-dom";
import moment from "moment";

const HardwareRequirements = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full overflow-y-auto overflow-x-hidden flex flex-col justify-start items-center">
      <div className="min-h-14 h-14 w-full bg-black flex flex-row justify-center items-center">
        <div className="w-full max-w-[1200px] flex flex-row justify-between items-center text-white h-14">
          <p className="font-display text-2xl cursor-pointer">
            Hi-Tech Power Systems
          </p>
          <div className="flex flex-row justify-end gap-4 items-center">
            <p className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer">
              Contact Us
            </p>
            <p
              className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Client Portal
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start my-8 w-full max-w-[1200px] mx-auto items-start gap-2">
        <h1 className="font-black text-xl font-body mb-4">
          Systems Requirements For Signing
        </h1>
        <p className="w-full">
          Most modern computers work well with HyperClient portal eSignature.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Browsers
        </h3>
        <p className="w-full">
          HyperClient Portal's eSignature supports the latest stable release
          (except where noted) of these browsers:
          <ul className="list-disc w-11/12 mx-auto my-2">
            <li className="w-full">Chrome</li>
            <li className="w-full">Firefox</li>
            <li className="w-full">Microsoft</li>
            <li className="w-full">Edge</li>
            <li className="w-full">Safari</li>
          </ul>
        </p>
        <p className="mx-auto bg-gray-200 py-4 px-8">
          <span className="font-bold">Note:</span> Prerelease (such as Beta)
          versions of operating systems and browsers are not supported.
        </p>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Recommended screen resolution
        </h3>
        <ul className="list-disc w-11/12 mx-auto my-2">
          <li className="w-full">1024 x 768</li>
        </ul>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Security settings
        </h3>
        <ul className="list-disc w-11/12 mx-auto my-2">
          <li className="w-full">Allow per session cookies</li>
          <li className="w-full">
            User accessing the Internet behind a Proxy Server must enable HTTP
            1.1 settings through a proxy connection
          </li>
        </ul>
        <h3 className="w-full text-left text-lg font-body font-bold">
          PDF reader
        </h3>
        <ul className="list-disc w-11/12 mx-auto my-2">
          <li className="w-full">
            Acrobat Reader or similar software might be required to view PDF
            files.
          </li>
          <li className="w-full">
            Your PDF reader must be able to open PDF 1.5+
          </li>
        </ul>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Mobile signing
        </h3>
        <ul className="list-disc w-11/12 mx-auto my-2">
          <li className="w-full">
            Apple
            <ul className="list-disc w-11/12 mx-auto">
              <li className="w-full">iPhone iOS 13.0+</li>
              <li className="w-full">iPad iOS 3.0+</li>
              <li className="w-full">iPod touch iOS 3.0+</li>
            </ul>
          </li>
          <li className="w-full">Android 5.0+</li>
          <li className="w-full">
            Windows
            <ul className="list-disc w-11/12 mx-auto">
              <li className="w-full">Windows 8+</li>
              <li className="w-full">Windows Phone</li>
            </ul>
          </li>
        </ul>
        <h3 className="w-full text-left text-lg font-body font-bold">
          HyperClient Portal Notary remote signing
        </h3>
        <p className="w-full">
          In addition to the general signing requirements, HyperClient Portal
          Notary remote signing has these specific requirements.
        </p>
        <ul className="list-disc w-11/12 mx-auto my-2">
          <li className="w-full">
            Devices: A laptop, desktop or mobile device with a working camera
            and microphone.
          </li>
        </ul>
        <p className="mx-auto bg-gray-200 py-4 px-8">
          <span className="font-bold">Note:</span> For best results, we
          recommend notaries and signers use a laptop or desktop device. Signers
          can optionally use mobile devices.
        </p>
        <ul className="list-disc w-11/12 mx-auto my-2">
          <li className="w-full">
            Browsers
            <ul className="list-disc w-11/12 mx-auto">
              <li className="w-full">
                Windows
                <ul className="list-disc w-11/12 mx-auto">
                  <li className="w-full">Google Chrome current version/</li>
                  <li className="w-full">Firefox current version</li>
                  <li className="w-full">
                    Microsoft Edge current version for signers
                  </li>
                </ul>
              </li>
              <li className="w-full">
                macOS
                <ul className="list-disc w-11/12 mx-auto">
                  <li className="w-full">Safari (macOS only) 12 +</li>
                  <li className="w-full">Google Chrome current version</li>
                  <li className="w-full">Firefox current version</li>

                  <li className="w-full">Microsoft Edge current version</li>
                </ul>
              </li>
              <li className="w-full">
                Mobile device: signers only; notary users are not supported.
                <ul className="list-disc w-11/12 mx-auto">
                  <li className="w-full">Apple iOS 11 +</li>
                  <li className="w-full">Android 5.0 +</li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="w-full">
            Internet connection
            <ul className="list-disc w-11/12 mx-auto">
              <li className="w-full">
                User internet access broadband service must support a live,
                real-time audio and video connection. For best results,we
                recommend that you hard-wire your device to the internet.
              </li>
              <li className="w-full">
                We recommend the following minimum internet speeds. Test your
                internet speed at{" "}
                <a
                  href="https://speedtest.net"
                  className="underline underline-offset-2 text-blue-500"
                >
                  Speedtest.net
                </a>
                .
                <ul className="list-disc w-11/12 mx-auto">
                  <li className="w-full">Upload: 5Mbps</li>
                  <li className="w-full">Download: 5Mbps</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h3 className="w-full text-left text-lg font-body font-bold">
          Signing with assistive technologies
        </h3>
        <p className="w-full">
          HyperClient Portal leverages different assistive technologies to
          ensure that all users are able to sign our documents. We support
          using:
        </p>
        <ul className="list-disc w-11/12 mx-auto my-2">
          <li className="w-full">
            Screen readers that allow visually impaired users to follow the
            necessary guidelines to sign an envelope
          </li>
          <li className="w-full">
            Keyboard-only use to make sure that any action can only be executed
            using this device
          </li>
          <li className="w-full">
            Enabling high contrast mode in all Operating Systems that allows
            colorblind users to have a better experience while signing a
            document
          </li>
        </ul>
      </div>
      <div className="w-full flex flex-row justify-center items-center border-t border-gray-300">
        <div className="h-14 w-full max-w-[1200px] mx-auto flex flex-row justify-between items-center text-xs py-3">
          <p>
            All rights reserved © Copyright 1986 - {moment().format("YYYY")} |{" "}
            <a
              href="https://hitechpowersystems.com"
              target="_blank"
              rel="noreferrer"
              className="font-medium"
            >
              Hi-Tech Power Systems
            </a>
          </p>
          <p>
            Powered by{" "}
            <a
              href="https://liveuptothehype.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-150 ease-in-out hover:text-[#f20c2e] font-medium"
            >
              Hypertek Solutions LLC
            </a>{" "}
            | Live up to the hype!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HardwareRequirements;
