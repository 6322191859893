import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { GetDashboard, AcceptQuote, RejectQuote } from "../actions/auth";
import { GenerateQuotePDF } from "../components/pdf";
import { GetIP } from "../actions/util";
import ReactSignatureCanvas from "react-signature-canvas";
import moment from "moment";
import toast from "react-hot-toast";

const Dashboard = ({ authState, authDispatch }) => {
  const [quotesData, setQuotesData] = useState([]);
  const [companyData, setCompanyData] = useState(null);
  const [open, setOpen] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [poNumber, setPONumber] = useState("");
  const [notes, setNotes] = useState("");

  const navigate = useNavigate();
  var canvasRef = useRef(null);

  const logout = () => {
    localStorage.removeItem("auth-token");
    authDispatch({ type: "logged-out" });
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    let inView = true;
    if (inView) {
      setLoading(true);
      reloadData();
    }
    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    setLoading(true);
    GetDashboard()
      .then((res) => {
        let quotes = res.data.quotes;
        quotes.sort((a, b) => moment(a.quoteDate) - moment(b.quoteDate));
        setQuotesData(quotes);
        setCompanyData(res.data.company);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const openLocation = (location) => {
    if (location === "Augusta") {
      window.open("https://goo.gl/maps/Hv54mK3uT9tdZHfi6", "_blank", "noreferrer");
    } else if (location === "North Augusta") {
      window.open("https://goo.gl/maps/YsYqNdpG7YK5kXyj9", "_blank", "noreferrer");
    }
  };

  const currencyFormat = (value) => {
    let formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
    return formatted;
  };

  const renderQuoteDetails = (quote) => {
    if (quote.quoteStatus === "sent" || quote.quoteStatus === "approved") {
      return (
        <>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Status:</p>
            <p className="text-sm font-medium">Awaiting Approval</p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Expires in:</p>
            <p className="text-sm font-medium">{quote.expiresIn}</p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Point of Contact:</p>
            <p className="text-sm font-medium">{quote.contactPerson}</p>
          </div>
        </>
      );
    } else if (quote.quoteStatus === "accepted" || quote.quoteStatus === "jobCreated") {
      return (
        <>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Status:</p>
            <p className="text-sm font-medium">
              {quote.quoteStatus === "accepted" ? "Accepted" : quote.quoteStatus === "jobCreated" ? "Job Created" : "Accepted"}
            </p>
          </div>
          {quote.approvedBy.length > 0 ? (
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm">Accepted By:</p>
              <p className="text-sm font-medium">{quote.approvedBy}</p>
            </div>
          ) : null}
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Accepted On:</p>
            <p className="text-sm font-medium">{moment(quote.approvedOn).format("MMMM Do YYYY")}</p>
          </div>
        </>
      );
    } else if (quote.quoteStatus === "declined") {
      return (
        <>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Status:</p>
            <p className="text-sm font-medium">Rejected</p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Rejected By:</p>
            <p className="text-sm font-medium">{quote.rejectedBy}</p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm">Rejected On:</p>
            <p className="text-sm font-medium">{moment(quote.rejectedOn).format("MMMM Do YYYY")}</p>
          </div>
        </>
      );
    }
  };

  const printQuote = async (quote) => {
    let data = {
      quote: quote,
      customer: companyData,
    };
    let detail = "none";
    if (quote.detail === "subtotals") {
      detail = "group";
    } else if (quote.detail === "itemized") {
      detail = "all";
    }
    let doc = await GenerateQuotePDF(data, detail);
    doc.setProperties({
      title: `Estimate - ${quote.quoteNo}`,
      subject: `Estimate - ${quote.quoteNo}`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  const acceptQuote = (quote) => {
    setQuoteData(quote);
    setAcceptModal(true);
  };

  const rejectQuote = (quote) => {
    setQuoteData(quote);
    setRejectModal(true);
  };

  const renderQuoteActions = (quote) => {
    if (quote.quoteStatus === "approved" || quote.quoteStatus === "sent") {
      return (
        <>
          <button
            className="px-3 py-2 font-sans text-sm font-bold text-white uppercase transition-all duration-150 ease-in-out bg-black border border-black hover:bg-white hover:text-black"
            onClick={() => printQuote(quote)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
          <button
            className="bg-black border border-black text-white px-8 py-2 text-sm uppercase font-sans font-bold transition-all duration-150 ease-in-out hover:bg-[#ff220c] hover:border-[#ff220c]"
            onClick={() => rejectQuote(quote)}
          >
            Reject Quote
          </button>
          <button
            className="bg-black border border-black text-white px-8 py-2 text-sm uppercase font-sans font-bold transition-all duration-150 ease-in-out hover:bg-[#38b000] hover:border-[#38b000]"
            onClick={() => acceptQuote(quote)}
          >
            Accept Quote
          </button>
        </>
      );
    } else if (quote.quoteStatus === "accepted" || quote.quoteStatus === "jobCreated") {
      return (
        <>
          <button
            className="px-3 py-2 font-sans text-sm font-bold text-white uppercase transition-all duration-150 ease-in-out bg-black border border-black hover:bg-white hover:text-black"
            onClick={() => printQuote(quote)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
        </>
      );
    } else if (quote.quoteStatus === "declined") {
      return (
        <>
          <button
            className="px-3 py-2 font-sans text-sm font-bold text-white uppercase transition-all duration-150 ease-in-out bg-black border border-black hover:bg-white hover:text-black"
            onClick={() => printQuote(quote)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
          <button className="bg-black border border-black text-white px-8 py-2 text-sm uppercase font-sans font-bold transition-all duration-150 ease-in-out hover:bg-[#38b000] hover:border-[#38b000]">
            Request re-quoting
          </button>
        </>
      );
    }
  };

  const renderQuote = (quote) => {
    return (
      <div
        className="w-full border border-gray-100 shadow-md flex flex-col justify-start items-center hover:scale-[1.01] hover:shadow-xl transition-all ease-in-out duration-150"
        key={quote.quoteNo}
      >
        <div className="flex flex-row justify-between w-full px-8 py-2 text-sm bg-gray-50">
          <p>{quote.quoteNo}</p>
          <p>Received: {moment(quote.quoteDate).format("MMMM Do YYYY[, at ]hh:mm A")}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full gap-4 px-8 py-6">
          <div className="flex-col items-center justify-center gap-2 text-left w-60">{renderQuoteDetails(quote)}</div>
          <div className={`flex-col justify-center items-center gap-2 text-left w-56 ${quote.detail === "totals" ? "hidden" : "visible"}`}>
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm">Labor:</p>
              <p className="text-sm font-medium">{currencyFormat(quote.laborTotal)}</p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm">Parts:</p>
              <p className="text-sm font-medium">{currencyFormat(quote.partsTotal)}</p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm">Gas, Oil & Grease:</p>
              <p className="text-sm font-medium">{currencyFormat(quote.gogTotal)}</p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm">Misc. Fees:</p>
              <p className="text-sm font-medium">{currencyFormat(quote.miscFees)}</p>
            </div>
          </div>
          <div className="h-full w-[1px] bg-black" />
          <div className="flex-col justify-center gap-2 -ml-20 text-right items-right">
            <p className="text-xl font-bold">Estimate Total:</p>
            <p className="text-lg font-semibold">{currencyFormat(quote.total)}</p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full gap-4 px-8 py-4">
          <div className="flex flex-row items-center justify-start gap-4">
            <p className="text-sm font-light">
              Location:{" "}
              <span onClick={() => openLocation(quote.location)} className="font-normal cursor-pointer">
                {quote.location}
              </span>
            </p>
          </div>
          <div className="flex flex-row items-center justify-end gap-2">
            <button
              className={`bg-white border border-black text-black px-8 py-2 text-sm uppercase font-sans font-bold transition-all duration-150 ease-in-out hover:bg-black hover:text-white ${
                quote.detail === "itemized" ? "visible" : "hidden"
              }`}
              onClick={() => openOneQuote(quote)}
            >
              View Details
            </button>
            {renderQuoteActions(quote)}
          </div>
        </div>
      </div>
    );
  };

  const openOneQuote = (data) => {
    setQuoteData(data);
    setOpen(true);
  };

  const closeDetails = () => {
    setOpen(false);
    setQuoteData(null);
  };

  const renderLaborDetails = () => {
    if (quoteData && quoteData.labor.length > 0) {
      return (
        <>
          <p className="w-full text-lg font-black text-left font-body">Labor</p>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-700 sm:pl-6 md:pl-0">
                  Hours
                </th>
                <th scope="col" className="py-3.5 px-3 text-left text-sm font-normal text-gray-700">
                  Rate
                </th>
                <th scope="col" className="py-3.5 px-3 text-sm font-normal text-gray-700 text-right">
                  Per Hour
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-0 sm:pr-6 md:pr-0 text-right text-sm font-semibold">
                  Ext. Price
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {quoteData.labor.map((el) => (
                <tr key={el.laborId}>
                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap sm:pl-6 md:pl-0">{el.hours}</td>
                  <td className="px-3 py-4 text-sm text-left text-gray-700 whitespace-nowrap">{el.rate}</td>
                  <td className="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap">{currencyFormat(el.perHour)}</td>
                  <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0">{currencyFormat(el.extPrice)}</td>
                </tr>
              ))}
              {quoteData.misc.map((el) => {
                if (el.miscType === "service") {
                  return (
                    <tr key={el.miscId}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap sm:pl-6 md:pl-0">{el.quantity}</td>
                      <td className="px-3 py-4 text-sm text-left text-gray-700 whitespace-nowrap">SPEC</td>
                      <td className="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap">{currencyFormat(el.chargeOutPrice / el.quantity)}</td>
                      <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0">
                        {currencyFormat(el.chargeOutPrice)}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        </>
      );
    }
  };

  const renderPartsDetails = () => {
    if (quoteData && quoteData.parts.length > 0) {
      return (
        <>
          <p className="w-full text-lg font-black text-left font-body">Parts</p>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-700 sm:pl-6 md:pl-0">
                  Quantity
                </th>
                <th scope="col" className="py-3.5 px-3 text-left text-sm font-normal text-gray-700">
                  Part #
                </th>
                <th scope="col" className="py-3.5 px-3 text-sm font-normal text-gray-700 text-right">
                  Price Per
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-0 sm:pr-6 md:pr-0 text-right text-sm font-semibold">
                  Ext. Price
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {quoteData.parts.map((el) => (
                <tr key={el.laborId}>
                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap sm:pl-6 md:pl-0">{el.quantity}</td>
                  <td className="px-3 py-4 text-sm text-left text-gray-700 whitespace-nowrap">{el.partNo}</td>
                  <td className="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap">{currencyFormat(el.pricePerPart)}</td>
                  <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0">{currencyFormat(el.extPrice)}</td>
                </tr>
              ))}
              {quoteData.misc.map((el) => {
                if (el.miscType === "part") {
                  return (
                    <tr key={el.miscId}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap sm:pl-6 md:pl-0">{el.quantity}</td>
                      <td className="px-3 py-4 text-sm text-left text-gray-700 whitespace-nowrap">SP-PART</td>
                      <td className="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap">{currencyFormat(el.chargeOutPrice / el.quantity)}</td>
                      <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0">
                        {currencyFormat(el.chargeOutPrice)}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        </>
      );
    }
  };

  const renderMiscDetails = () => {
    if (quoteData) {
      if (quoteData.freight.length > 0 || quoteData.loadbank.length > 0 || quoteData.parking.length > 0) {
        return (
          <>
            <p className="w-full text-lg font-black text-left font-body">Misc. Fees</p>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-700 sm:pl-6 md:pl-0">
                    Quantity
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-normal text-gray-700">
                    Fee Code
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-sm font-normal text-gray-700 text-right">
                    Price Per
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-0 sm:pr-6 md:pr-0 text-right text-sm font-semibold">
                    Ext. Price
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {quoteData.freight.map((el) => (
                  <tr key={el.freightId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap sm:pl-6 md:pl-0">1</td>
                    <td className="px-3 py-4 text-sm text-left text-gray-700 whitespace-nowrap">FREIGHT</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap">{currencyFormat(el.freightChargeOut)}</td>
                    <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0">
                      {currencyFormat(el.freightChargeOut)}
                    </td>
                  </tr>
                ))}
                {quoteData.loadbank.map((el) => (
                  <tr key={el.loadbankId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap sm:pl-6 md:pl-0">{el.quantity}</td>
                    <td className="px-3 py-4 text-sm text-left text-gray-700 whitespace-nowrap">LOADBANK</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap">{currencyFormat(el.unitCharge)}</td>
                    <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0">
                      {currencyFormat(el.unitCharge)}
                    </td>
                  </tr>
                ))}
                {quoteData.parking.map((el) => (
                  <tr key={el.parking}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap sm:pl-6 md:pl-0">{el.quantity}</td>
                    <td className="px-3 py-4 text-sm text-left text-gray-700 whitespace-nowrap">{el.parkingType.toUpperCase()}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap">{currencyFormat(el.unitCharge)}</td>
                    <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0">
                      {currencyFormat(el.totalCharge)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
    }
  };

  const calculateMisc = (data) => {
    let total = 0;
    for (let i = 0; i < data.freight.length; i++) {
      total += data.freight[i].freightChargeOut;
    }
    for (let i = 0; i < data.loadbank.length; i++) {
      total += data.loadbank[i].unitCharge;
    }
    for (let i = 0; i < data.parking.length; i++) {
      total += data.parking[i].totalCharge;
    }
    return total;
  };

  const calculateShopFee = (data) => {
    let total = 0;
    for (let i = 0; i < data.shopFee.length; i++) {
      total += data.shopFee[i].chargeOutPrice;
    }
    return total;
  };

  const renderItemizedDrawer = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeDetails()}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
                      <div className="w-11/12 py-6 mx-auto">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">Quote Details</Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button
                              type="button"
                              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-0 focus:ring-transparent"
                              onClick={() => closeDetails()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="w-full mb-2 border-b border-black" />
                      {quoteData ? (
                        <div className="flex-1 overflow-y-auto divide-y divide-gray-200">
                          <div className="flex flex-col items-center justify-start w-11/12 mx-auto">
                            {renderLaborDetails()}
                            {renderPartsDetails()}
                            {renderMiscDetails()}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center flex-1 w-full gap-4">
                          <svg className="w-8 h-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25 stroke-black" cx="12" cy="12" r="10" stroke="#c9c9c9" strokeWidth="4"></circle>
                            <path
                              className="opacity-75 fill-black"
                              fill="#c9c9c9"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <p className="text-sm font-medium text-center opacity-85 animate-pulse">Loading...</p>
                        </div>
                      )}
                      {quoteData ? (
                        <div className="flex flex-col items-center justify-center w-11/12 py-4 mx-auto border-t border-gray-300">
                          <div className="flex flex-row items-center justify-between w-full">
                            <p className="text-sm">Labor:</p>
                            <p className="text-sm font-medium">{currencyFormat(quoteData.laborTotal)}</p>
                          </div>
                          <div className="flex flex-row items-center justify-between w-full">
                            <p className="text-sm">Parts:</p>
                            <p className="text-sm font-medium">{currencyFormat(quoteData.partsTotal)}</p>
                          </div>
                          <div className="flex flex-row items-center justify-between w-full">
                            <p className="text-sm">Gas, Oil & Grease:</p>
                            <p className="text-sm font-medium">{currencyFormat(quoteData.gogTotal)}</p>
                          </div>
                          <div className="flex flex-row items-center justify-between w-full">
                            <p className="text-sm">Misc. Fees:</p>
                            <p className="text-sm font-medium">{currencyFormat(calculateMisc(quoteData))}</p>
                          </div>
                          <div className="flex flex-row items-center justify-between w-full">
                            <p className="text-sm">Shop Fee:</p>
                            <p className="text-sm font-medium">{currencyFormat(calculateShopFee(quoteData))}</p>
                          </div>
                          <div className="flex flex-row items-center justify-between w-full pt-1 mt-1 border-t border-gray-500">
                            <p className="text-sm font-medium uppercase">Estimate Total:</p>
                            <p className="text-sm font-medium">{currencyFormat(quoteData.total)}</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAcceptModal = () => {
    setAcceptModal(false);
    setQuoteData(null);
    setTermsAccepted(false);
    setName("");
    setNotes("");
    setPONumber("");
    canvasRef.clear();
  };

  const signAndAccept = () => {
    setLoading(true);
    let failed = false;
    let sigData;
    if (!termsAccepted) {
      failed = true;
      toast.error("Please agree to use electronic records and signatures.");
    }
    if (!failed && name.length < 4 && !name.includes(" ")) {
      failed = true;
      toast.error("Please enter a valid name.");
    }
    if (!failed && title.length < 2) {
      failed = true;
      toast.error("Please enter a valid title.");
    }
    if (!failed && canvasRef.isEmpty()) {
      failed = true;
      toast.error("Please sign before accepting.");
    }
    if (!failed) {
      sigData = canvasRef.toDataURL("png", 100);
      let toSend = {
        signedBy: name,
        termsAccepted: termsAccepted,
        signature: sigData,
        quoteId: quoteData.quoteId,
        title: title,
        notes: notes,
        poNumber: poNumber,
      };
      GetIP()
        .then((res) => {
          toSend.ip = res.data.IPv4;
          AcceptQuote(toSend)
            .then((res) => {
              toast.success("Success");
              closeAcceptModal();
              reloadData();
            })
            .catch((err) => {
              toast.error("Something went wrong, please try again.");
              setLoading(false);
            });
        })
        .catch((err) => {
          AcceptQuote(toSend)
            .then((res) => {
              toast.success("Success");
              closeAcceptModal();
              reloadData();
            })
            .catch((err) => {
              toast.error("Something went wrong, please try again.");
              setLoading(false);
            });
        });
    } else {
      setLoading(false);
    }
  };

  const renderAcceptModal = () => {
    return (
      <Transition.Root show={acceptModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeAcceptModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div key="accept-content">
                    <div className="text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Accept Quote {quoteData ? quoteData.quoteNo : null}
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start w-full mt-2 border-t border-black" key="accept-signature">
                        <label htmlFor="fullName" className="block w-full mt-2 text-sm font-medium text-left text-black font-body">
                          Your Full Name
                        </label>
                        <div className="w-full mt-1 text-left">
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <label htmlFor="title" className="block w-full mt-2 text-sm font-medium text-left text-black font-body">
                          Your Title
                        </label>
                        <div className="w-full mt-1 text-left">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <label htmlFor="poNumber" className="block w-full mt-2 text-sm font-medium text-left text-black font-body">
                          PO Number
                        </label>
                        <div className="w-full mt-1 text-left">
                          <input
                            type="text"
                            name="poNumber"
                            id="poNumber"
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                            onChange={(e) => setPONumber(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-row items-center justify-between w-full gap-2">
                          <p className="block w-full mt-2 text-sm font-medium text-left text-black font-body">Your Signature</p>
                          <div className="flex flex-col items-center justify-center">
                            <ReactSignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: 400,
                                height: 180,
                                className: "border border-dashed border-gray-300 mt-4 rounded-md",
                              }}
                              backgroundColor="rgba(234,234,234,0.05)"
                              ref={(ref) => {
                                canvasRef = ref;
                              }}
                              throttle={10}
                            />

                            <div className="w-full flex flex-row justify-end items-center mt-2 max-w-[402px] mx-auto">
                              <button
                                className="px-4 py-1 text-sm text-white transition-all duration-150 ease-in-out bg-black border border-black hover:text-black hover:bg-white"
                                onClick={() => canvasRef.clear()}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                        <label htmlFor="notes" className="w-full text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-start">
                          Acceptance Notes
                        </label>
                        <div className="w-full mt-1 sm:mt-0">
                          <textarea
                            id="notes"
                            name="notes"
                            rows={3}
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-black focus:ring-transparent sm:text-sm"
                            defaultValue={""}
                            onChange={(e) => setNotes(e.target.value)}
                          />
                          <p className="mt-2 text-sm text-gray-500">Add any acceptance notes you may have</p>
                        </div>
                        <div className="w-full py-2 my-2 border-black mt-y border-y" key="accept-disclosure">
                          <p className="text-sm text-center text-gray-500">
                            Please read the{" "}
                            <a
                              href="/signatureDisclosure"
                              target="_blank"
                              rel="noreferrer"
                              className="underline transition-all duration-150 ease-in-out underline-offset-1 hover:text-black"
                            >
                              Electronic Record and Signature Disclosure
                            </a>
                            .
                          </p>
                          <legend className="sr-only">Accept to use electronic records and signatures</legend>
                          <div className="relative flex items-center justify-center mt-1">
                            <div className="mr-3 text-sm">
                              <label htmlFor="comments" className="text-gray-700">
                                I agree to use electronic records and signatures
                              </label>
                            </div>
                            <div className="flex items-center h-5">
                              <input
                                id="comments"
                                aria-describedby="electronic-records-and-signature-disclosure"
                                name="comments"
                                type="checkbox"
                                className="w-4 h-4 text-green-600 transition-all duration-150 ease-in-out border-gray-300 rounded focus:ring-transparent"
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                              />
                            </div>
                          </div>
                        </div>
                        <p className="w-full mt-1 text-sm text-gray-800">
                          By approval below, we accept the above bill of material, price, and terms, as described in Hi-Tech Power Systems Estimate #
                          {quoteData ? quoteData.quoteNo : null}, and request that the order be released for production.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end gap-3 pt-4 mt-3 border-t border-black" key="controls">
                    <button
                      type="button"
                      className="px-4 py-2 mt-3 text-base font-medium text-black transition-all duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:border-black focus:outline-none focus:ring-0 focus:ring-transparent sm:mt-0 sm:text-sm"
                      onClick={() => closeAcceptModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="px-4 py-2 text-base font-medium text-white transition-all duration-150 ease-in-out bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                      onClick={() => signAndAccept()}
                    >
                      Sign and Accept
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeRejectModal = () => {
    setRejectModal(false);
    setName("");
    setNotes("");
  };

  const submitReject = () => {
    setLoading(true);
    let failed = false;
    if (name.length < 2 || !name.includes("")) {
      failed = true;
      toast.error("Please enter a valid, full name.");
    }
    if (!failed) {
      let toSend = {
        quoteId: quoteData.quoteId,
        name: name,
        notes: notes,
      };
      GetIP()
        .then((res) => {
          toSend.ip = res.data.IPV4;
          RejectQuote(toSend)
            .then((res) => {
              toast.success("Quote Rejected");
              closeRejectModal();
              reloadData();
            })
            .catch((err) => {
              toast.error("Failed to reject quote, please try again");
              setLoading(false);
            });
        })
        .catch((err) => {
          RejectQuote(toSend)
            .then((res) => {
              toast.success("Quote Rejected");
              closeRejectModal();
              reloadData();
            })
            .catch((err) => {
              toast.error("Failed to reject quote, please try again");
              setLoading(false);
            });
        });
    } else {
      setLoading(false);
    }
  };

  const renderRejectModal = () => {
    return (
      <Transition.Root show={rejectModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeRejectModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div key="accept-content">
                    <div className="text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Reject Quote {quoteData ? quoteData.quoteNo : null}
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start w-full mt-2 border-t border-black" key="reject-signature">
                        <label htmlFor="fullName" className="block w-full mt-2 text-sm font-medium text-left text-black font-body">
                          Your Full Name
                        </label>
                        <div className="w-full mt-1 text-left">
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-black focus:ring-transparent focus:ring-0 sm:text-sm"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <label htmlFor="notes" className="w-full text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-start">
                          Reason for rejection
                        </label>
                        <div className="w-full mt-1 sm:mt-0">
                          <textarea
                            id="notes"
                            name="notes"
                            rows={3}
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-black focus:ring-transparent sm:text-sm"
                            defaultValue={""}
                            onChange={(e) => setNotes(e.target.value)}
                          />
                          <p className="w-full mt-2 text-sm text-right text-gray-500">Add any rejection notes you may have</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end gap-3 pt-4 mt-3 border-t border-gray-400" key="controls">
                    <button
                      type="button"
                      className="px-4 py-2 mt-3 text-base font-medium text-black transition-all duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:border-black focus:outline-none focus:ring-0 focus:ring-transparent sm:mt-0 sm:text-sm"
                      onClick={() => closeRejectModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-transparent bg-[#eb2f06] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#C72905] focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm transition-all ease-in-out duration-150"
                      onClick={() => submitReject()}
                    >
                      Reject Quote
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row items-center justify-center w-full bg-black h-14">
        <div className="w-full max-w-[1440px] flex flex-row justify-between items-center text-white h-14">
          <p className="text-2xl cursor-pointer font-display">Hi-Tech Power Systems</p>
          <div className="flex flex-row items-center justify-end gap-4">
            <p
              className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#FFC312] cursor-pointer"
              onClick={() => navigate("/contactUs")}
            >
              Contact Us
            </p>
            <p className="uppercase font-semibold text-sm transition-all ease-in-out duration-150 hover:text-[#eb2f06] cursor-pointer" onClick={() => logout()}>
              Log Out
            </p>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[1440px] flex flex-col justify-start items-center mt-6 flex-grow">
        <h1 className="text-3xl font-bold">Welcome Back!</h1>
        <p className="text-xs font-medium text-gray-400 uppercase">Let's review what's new</p>
        <div className="flex flex-row items-center justify-between flex-grow w-full gap-5 mt-6">
          <div className="flex flex-col justify-start border-r border-r-black h-full w-[350px] py-8 px-12 gap-3">
            <button
              className="font-body font-black uppercase text-xl border-l-4 border-[#ffc312] pl-6 text-left hover:bg-slate-100 hover:text-slate-800 duration-150 py-2"
              onClick={() => navigate("/")}
            >
              My Quotes
            </button>
            <button
              className="py-2 pl-6 text-xl font-black text-left uppercase duration-150 font-body hover:bg-slate-100 hover:text-slate-800"
              onClick={() => navigate("/invoices")}
            >
              My Invoices
            </button>
            <button
              className="py-2 pl-6 text-xl font-black text-left uppercase duration-150 font-body hover:bg-slate-100 hover:text-slate-800"
              onClick={() => navigate("/paymentHistory")}
            >
              Payment History
            </button>
            <button
              className="py-2 pl-6 text-xl font-black text-left uppercase duration-150 font-body hover:bg-slate-100 hover:text-slate-800"
              onClick={() => navigate("/contactUs")}
            >
              Contact Us
            </button>
          </div>
          <div className="flex flex-col items-start justify-start flex-grow h-full gap-4 px-12 py-10">
            {!loading && quotesData.length > 0 ? (
              quotesData.map((quote) => renderQuote(quote))
            ) : loading ? (
              <div className="flex flex-col items-center justify-center flex-1 w-full gap-4">
                <svg className="w-8 h-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25 stroke-black" cx="12" cy="12" r="10" stroke="#c9c9c9" strokeWidth="4"></circle>
                  <path
                    className="opacity-75 fill-black"
                    fill="#c9c9c9"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <p className="text-sm font-medium text-center opacity-85 animate-pulse">Loading...</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center flex-1 w-full gap-4">
                <p className="text-sm font-medium text-center opacity-85">No Previous Quotes Found</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full text-xs h-14">
          <p>
            All rights reserved © Copyright 1986 - {moment().format("YYYY")} |{" "}
            <a href="https://hitechpowersystems.com" target="_blank" rel="noreferrer" className="font-medium">
              Hi-Tech Power Systems
            </a>
          </p>
          <p>
            Powered by{" "}
            <a
              href="https://liveuptothehype.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-150 ease-in-out hover:text-[#f20c2e] font-medium"
            >
              Hypertek Solutions LLC
            </a>{" "}
            | Live up to the hype!
          </p>
        </div>
      </div>
      {renderItemizedDrawer()}
      {renderAcceptModal()}
      {renderRejectModal()}
    </div>
  );
};

export default Dashboard;
